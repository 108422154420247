import "./CustomerList.scss"
import ContentCreatorSidebar from "../../../components/sidebars/ContentCreatorSidebar"
import Datatable from "../../../components/datatable/Datatable"
import Navbar from "../../../components/Navbar";
import React from "react";

const CustomerList = () => {
    return (
        <div className="customer-list">
          <Navbar />
          <div className="customer-list-container">
            <ContentCreatorSidebar/>
            <div className="customer-list-body">
                <Datatable/>
            </div>
          </div>
        </div>
    )
}

export default CustomerList