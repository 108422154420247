import React, {useState} from "react";
import {Alert} from "../../../pages/Alert";
import {Link, useNavigate} from "react-router-dom";
import "./CompanyAcquisitionForm.scss";

const CompanyAcquisitionForm = ({contentForm}) => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [role, setRole] = useState("");
  const [positionInterest, setPositionInterest] = useState("");

  const [error, setError] = useState("");
  const navigate = useNavigate();

  const validEmailRegExp = new RegExp("([A-Za-z0-9]+[A-Za-z0-9._]+[@][a-z]*[.][a-z]{3})");

  const fetchData = async (body) => {
    console.log(body);
    const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/addForm',
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          body: JSON.stringify(body)
        });
    const jsonResult = await result.json();
    console.log(jsonResult);
    return jsonResult;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    let formError = validateForm();
    console.log("ENTER");
    console.log(formError);
    if (formError === "") {
      let formData = buildFormData();
      fetchData(formData).then(result => {
        console.log("downloaded " + result);
        console.log("Navigating " + result);
        navigate(contentForm.ctaButtonPath);
      });
    } else {
      setError(formError);
    }
  };

  const validateForm = () => {
    if (name === "" || name === "undefined") {
      return "Name could not be empty";
    }
    console.log(email);
    if (!validEmailRegExp.test(email)) {
      return "Email is not following a correct pattern";
    }
    if (websiteUrl === "" || websiteUrl === "undefined") {
      return "Your url could not be empty";
    }
    if (role === "" || role === "undefined") {
      return "Your Role could not be empty";
    }
    if (positionInterest === "" || positionInterest === "undefined") {
      return "You must be looking for some positions";
    }
    return "";
  }

  const buildFormData = () => {
    const bodyForm = {
      name : name,
      email: email,
      companyWebsiteUrl: websiteUrl,
      roleOfTheRequester: role,
      lookingFor: positionInterest
    };
    return {
      formType : "companyForm",
      bodyForm : bodyForm
    }
  }

  const handleNameInput = (nameInput) => {
    setName(nameInput);
  }

  const handleEmailInput = (emailInput) => {
    setEmail(emailInput);
  }

  const handleWebsiteUrlInput = (websiteUrlInput) => {
    setWebsiteUrl(websiteUrlInput);
  }

  const handleRoleInput = (roleInput) => {
    setRole(roleInput);
  }

  const handlePositionInterestInput = (positionInput) => {
    setPositionInterest(positionInput);
  }

  return (
      <div className="logo-titled-form-container">
        <div className="w-full py-16 max-w-lg m-auto text-black">

          <div className="logo-titled-form-text-container">
            <h1 className="logo-titled-form-title">{contentForm.title}</h1>
            <h2 className="logo-titled-form-subtitle">{contentForm.subtitle}</h2>
          </div>

          {error && <Alert message={error} />}

          <form
              onSubmit={handleSubmit}
              className="bg-white shadow-lg rounded-2xl px-8 pt-6 pb-6 mb-4"
          >
            <div className="mb-4">
              <label
                  htmlFor="name"
                  className="block text-gray-800 text-sm font-bold mb-2"
              >
                {contentForm.nameInput}
              </label>
              <input
                  type="text"
                  onChange={(e) => handleNameInput(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder={contentForm.namePlaceHolder}
              />
            </div>

            <div className="mb-4">
              <label
                  htmlFor="email"
                  className="block text-gray-800 text-sm font-bold mb-2"
              >
                {contentForm.emailInput}
              </label>
              <input
                  type="email"
                  onChange={(e) => handleEmailInput(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder={contentForm.emailPlaceHolder}
              />
            </div>

            <div className="mb-4">
              <label
                  htmlFor="company-website"
                  className="block text-gray-800 text-sm font-bold mb-2"
              >
                {contentForm.companyWebsiteInput}
              </label>
              <input
                  type="text"
                  onChange={(e) => handleWebsiteUrlInput(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder={contentForm.companyWebsitePlaceHolder}
              />
            </div>

            <div className="mb-4">
              <label
                  htmlFor="company-role"
                  className="block text-gray-800 text-sm font-bold mb-2"
              >
                {contentForm.roleCompanyInput}
              </label>
              <input
                  type="text"
                  onChange={(e) => handleRoleInput(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder={contentForm.roleCompanyPlaceHolder}
              />
            </div>

            <div className="mb-4">
              <label
                  htmlFor="position-interest"
                  className="block text-gray-800 text-sm font-bold mb-2"
              >
                {contentForm.lookingForProfessionalsInput}
              </label>
              <input
                  type="text"
                  onChange={(e) => handlePositionInterestInput(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder={contentForm.lookingForProfessionalsPlaceHolder}
              />
            </div>

            <div className="py-2">
              <button className="w-full bg-sky-600 hover:bg-sky-800 text-white rounded-full font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline">
                {contentForm.ctaButtonTitle}
              </button>
            </div>

          </form>
          <p className="my-4 text-sm flex justify-between px-3">
            {contentForm.contentCreatorQuestion}
            <Link to={contentForm.contentCreatorAcquisitionPath} className="text-sky-800 hover:text-sky-900">
              {contentForm.contentCreatorText}
            </Link>
          </p>
        </div>
      </div>
  );

};

export default CompanyAcquisitionForm;