import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import './FileUpload.scss'
import {useParams} from "react-router-dom";
import {useAuth} from "../../../context/AuthContext";

const FileUpload = ({ files, setFiles, removeFile, offerJobId }) => {

  const { authUser, userInfo } = useAuth();
  const { token } = useParams();

  const fetchData = async (body) => {
    const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/uploadFile',
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          body: body
        });
    const jsonResult = await result.json();
    console.log(jsonResult);
    return jsonResult;
  }

  const createHiringProcess = async () => {
    console.log(authUser.accessToken);
    const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/addHiringProcess',
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + authUser.accessToken,
            "Access-Control-Allow-Origin" : "*"
          },
          method: "POST",
          body: JSON.stringify({
            kareerrToken: encodeURIComponent(token)
          })
        });
    const jsonResult = await result.json();
    console.log(jsonResult);
    return jsonResult;
  }

  const uploadHandler = (event) => {
    const file = event.target.files[0];
    if(!file) return;
    file.isUploading = true;
    setFiles([...files, file])

    // upload file
    const formData = new FormData();
    formData.append(
        "file",
        file,
        file.name,
    );

    formData.append(
        "offerJobId",
        offerJobId,
    );

    formData.append(
        "userId",
        userInfo.userId,
    );

    formData.append(
        "usedToken",
        encodeURIComponent(token),
    );

    console.log(formData);

    fetchData(formData).then(result => {
      console.log("downloaded " + result);
      file.isUploading = false;
      console.log("UPLOADED FINISHED");
      setFiles([...files, file]);

      createHiringProcess().then(result => {
        console.log("downloaded " + result);
        console.log("HIRING PROCESS FINISHED");
      });

    });
  }

  return (
      <>
        <div className="file-card">

          <div className="file-inputs">
            <input type="file" onChange={uploadHandler} />
            <button>
              <i>
                <FontAwesomeIcon icon={faPlus} />
              </i>
              Upload
            </button>
          </div>
          <p className="file-main">Supported files</p>
          <p className="file-info">PDF, JPG, PNG</p>
        </div>
      </>
  )
}

export default FileUpload