import React, {useContext} from "react";
import Footer from "../../components/footer/Footer";
import CompanyAcquisitionForm from "../../components/forms/company/CompanyAcquisitionForm";
import {LanguageContext} from "../../context/LanguageContext";
import Navbar from "../../components/Navbar";

const CompanyAcquisition = () => {

  const { content, dispatchLanguage } = useContext(LanguageContext);

  return (
      <div className="company-acquisition-container">
        <Navbar/>
        <CompanyAcquisitionForm contentForm={content.companyAcquisitionContentForm}/>
        <Footer/>
      </div>
  )
}

export default CompanyAcquisition;