import React from "react";
import "./JobPositionExtraInformation.scss"

const JobPositionExtraInformation = ({title, extraInfos}) => {

  console.log(extraInfos);

  return (
      <div className="job-position-extra-info-card-container">
        <div className="job-position-extra-info-card-box">
          <div className="job-position-extra-info-title-container">
            <p className="job-position-extra-info-title">{title}</p>
          </div>
          <div className="job-position-extra-info-card-box-info">
            <p className="job-position-extra-info-card-box-info-label">Conditions</p>
            <p className="job-position-extra-info-card-box-info-value">{extraInfos.contractConditions}</p>
          </div>
          <div className="job-position-extra-info-card-box-info">
            <p className="job-position-extra-info-card-box-info-label">Location</p>
            <p className="job-position-extra-info-card-box-info-value">{extraInfos.location}</p>
          </div>
        </div>
      </div>
  )
}

export default JobPositionExtraInformation;