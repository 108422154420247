import {useContext} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Navbar from "../../components/Navbar";
import CardCollection from "../../components/cards/CardCollection";
import Footer from "../../components/footer/Footer";

const Confirmation = () => {

  const { content } = useContext(LanguageContext);

  return (
      <>
        <Navbar />
        <CardCollection type="withRoundedImage" collectionTitle={content.confirmationPage.confirmationCardTitle} cards={content.confirmationPage.confirmationCards} />
        <Footer />
      </>
  )
}

export default Confirmation;