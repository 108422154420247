import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import "./PostedJob.scss";
import Navbar from "../../../components/Navbar";
import DetailedJobPosition from "../../../components/jobs/DetailedJobPosition";
import ContentCreatorSidebar from "../../../components/sidebars/ContentCreatorSidebar";
import {useAuth} from "../../../context/AuthContext";

const PostedJob = () => {

  const { jobId } = useParams();
  const [data, setData] = useState();

  const { authUser } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/getJobById',
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + authUser.accessToken,
              "Access-Control-Allow-Origin" : "*"
            },
            method: "POST",
            body: JSON.stringify({
              jobId: encodeURIComponent(jobId)
            })
          });
      const jsonResult = await result.json();
      console.log(jsonResult);
      setData(jsonResult)
    }

    fetchData().then(result => console.log("downloaded " + result));
    //fetchDataFromFunction().then(() => console.log(" Finished "))
  }, []);

  return (
      <div>
        <Navbar />
        <div className="posted-job-body-page">
          <div className="posted-job-container">
            <ContentCreatorSidebar />
            <div className="posted-job-detailed-info-content">
              { data && <DetailedJobPosition jobInfo={data} isActionActive={false} /> }
            </div>
          </div>
        </div>
      </div>

  );
}

export default PostedJob;