import React from "react";
import "./DetailedJobPosition.scss"
import JobPositionExtraInformation from "./JobPositionExtraInformation";
import JobPositionSkills from "./JobPositionSkills";
import JobPositionBasicInfo from "./JobPositionBasicInfo";
import JobPositionBody from "./JobPositionBody";
import FileManager from "../forms/file/FileManager";

const DetailedJobPosition = ({jobInfo, isActionActive = true}) => {

  const skills = [
    "Inteligente", "Buena pesona", "Empático", "Divertido", "Trabajador"
  ];

  return (
      <div className="detailed-job-position-container">
        <JobPositionBasicInfo jobBasicInfo={jobInfo} />
        <div className="detailed-job-position-meta-information">
          <JobPositionExtraInformation title="Information" extraInfos={jobInfo} />
          <JobPositionSkills title="Habilidades" skills={skills} />
        </div>
        <JobPositionBody title="Description" text={jobInfo.description} />
        {isActionActive && <FileManager offerJobId={jobInfo.jobId} /> }
      </div>
  );
}

export default DetailedJobPosition;