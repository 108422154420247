import React from "react";
import {Link} from "react-router-dom";
import "./ImageSlideCard.scss";

const ImageSlideCard = ({slide}) => {

  return (
      <div className="image-slide-card">
        <Link to={slide.ctaPath}>
          <img src={slide.imgUrl} alt="" className="image-slide-card-image"/>
        </Link>
      </div>
  )
}

export default ImageSlideCard;