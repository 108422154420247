import React, {useState} from "react";
import "./OfferJobCard.scss"
import ClipLoader from "react-spinners/ClipLoader";
import {useAuth} from "../../context/AuthContext";

const OfferJobCard = ({offerId, imgUrl, title, subtitle, text}) => {

  const [jobToken, setJobToken] = useState();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const { authUser } = useAuth();

  const tokenize = async () => {
    setLoading(true);
    const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/createToken',
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + authUser.accessToken,
            "Access-Control-Allow-Origin" : "*"
          },
          method: "POST",
          body: JSON.stringify({
            productId: offerId,
            userId: authUser.uid
          })
        });
    const jsonResult = await result.json();
    setJobToken(jsonResult);
    setLoaded(true);
    setLoading(false);
  };

  return (
      <>
        <li className="card-item">
          <div className="job-card-offer">
            <img src={imgUrl} alt="" className="job-card-company-logo"/>
            <div className="card-item-info">
              <h3 className="job-card-item-title">
                {title}
              </h3>
              <h5 className="job-card-item-subtitle">
                {subtitle}
              </h5>
              <p className="job-card-item-text">{text}</p>
            </div>
            <div className="p-8 text-center w-121">
              {
                !loading && !jobToken && <button
                      className="w-40 bg-sky-600 hover:bg-sky-800 text-white font-bold py-2 px-4 rounded-3xl focus:outline-none focus:shadow-outline"
                      onClick={tokenize}
                  >
                    Tokenizar
                  </button>
              }
              {
                loading && <ClipLoader
                      color={"#090979"}
                      loading={loading}
                      size={40}
                  />
              }
              {
                !loading && loaded && jobToken && <p className="job-card-item-text">www.kareerr.com/offers/{jobToken.value}</p>
              }
            </div>
          </div>
        </li>
      </>
  )
}

export default OfferJobCard;