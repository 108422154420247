import React from "react";
import "./CardCollection.scss"
import CardFactory from "./CardFactory";

const CardCollectionRow = ({type = "", cards}) => {

  return (
      <>
        <ul className="card-collection-items">
          {
            cards?.map((card, index) => {
              return (
                  <CardFactory key={index} type={type} card ={card} />
              )
            })
          }
        </ul>
      </>
  )
}

export default CardCollectionRow;