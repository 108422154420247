const SpanishContent = {
  dashboard: "Panel",
  customers: "Clientes",
  products: "Productos",
  jobs: "Jobs",
  payments: "Pagos",
  stats: "Estadísticas",
  notifications: "Notificaciones",
  settings: "Configuración",
  profile: "Perfil",
  logout: "Logout",
  contributors: "Contribuyentes",
  jobOffers: "Mis ofertas",
  hiringProcess: "Mis procesos",
  homeNavBarContent: {
    companyName: "KAREERR",
    homeTab: "Home",
    contentCreatorTab: "Creadores de Contenido",
    dashboardTab: "Panel",
  },
  homeWidget: {
    title: "Los líderes del sector ayudan a hacer tu hiring de ensueño",
    subtitle: "No pierdas el tiempo ni en esfuerzo en canales que no aportan los mejores y más entusiastas profesionales del sector. Deja que los líderes de estos con sus comunidades te ayuden a encontrar tus mejores candidatos.",
    ctaButtonText: "Empieza",
    ctaUrl: "/hire-with-kareerr"
  },
  homeSlideCompaniesTitle: "Empresas que confían en nosotros",
  homeContentCompaniesSlides: [
    {
      ctaPath: "/edreams",
      imgUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/EDreams_ODIGEO_logo.svg/1600px-EDreams_ODIGEO_logo.svg.png",
      title: "eDreams",
      description: "Joan top!"
    },
    {
      ctaPath: "/wallapop",
      imgUrl: "https://ship-quik.com/wp-content/uploads/wallapop.png",
      title: "Wallapop",
      description: "Vende todo lo que quieras papa"
    },
    {
      ctaPath: "/factorial",
      imgUrl: "https://www.hubspot.es/hubfs/Logo_color.png",
      title: "Factorial",
      description: "Jordi, Bernat y ITnig TOPs"
    },
  ],
  homeSlideContentCreatorTitle: "Nuestros creadores de contenido",
  homeContentCreatorSlides: [
    {
      ctaPath: "/mouredev",
      imgUrl: "https://yt3.googleusercontent.com/ytc/AMLnZu8A1wAaWrc94LYTsQNxodZDeODCdOV8IGlU-5MUkw=s900-c-k-c0x00ffffff-no-rj",
      title: "MoureDev",
      description: "Programación y desarrollo de apps"
    },
    {
      ctaPath: "/midudev",
      imgUrl: "https://avatars.githubusercontent.com/u/1561955?v=4",
      title: "@midudev",
      description: "Miguel Ángel Durán"
    },
    {
      ctaPath: "/itnig",
      imgUrl: "https://img-static.ivoox.com/index.php?w=1024&h=1024&url=https://static-1.ivoox.com/canales/8/6/6/5/1271564005668_XXL.jpg?ts=1647969717",
      title: "ITNIG",
      description: "Historias de startups"
    }
  ],
  companyAcquisitionContentForm: {
    title: "Quieres encontrar a los mejores candidatos para tus posiciones?",
    subtitle: "Complete el siguiente formulario y programe una llamada para obtener más información de como.",
    nameInput: "Nombre",
    namePlaceHolder: "Nombre",
    emailInput: "Email",
    emailPlaceHolder: "youremail@company.tld",
    companyWebsiteInput: "Sitio web de la empresa",
    companyWebsitePlaceHolder: "https://kareerr.com/",
    roleCompanyInput: "Cuál es tu posición en la compañía?",
    roleCompanyPlaceHolder: "Tu posición",
    lookingForProfessionalsInput: "¿Para qué posición(es) estáis interesados?",
    lookingForProfessionalsPlaceHolder: "Ejemplo Ingeniero de Software",
    ctaButtonTitle: "Enviar",
    ctaButtonPath: "/confirmation",
    contentCreatorQuestion: "Eres creador de contenido?",
    contentCreatorText: "Regístrate",
    contentCreatorAcquisitionPath: "/join-kareerr"
  },
  creatorContentHomeWidget: {
    title: "Kareerr te paga para que sigas haciendo lo que amas.",
    subtitle: "Kareerr ayuda a las empresas a encontrar los mejores profesionales para sus posiciones de las comunidades de los creadores de contenido lideres en el sector pertinente.",
    ctaButtonText: "Empieza",
    ctaUrl: "/join-kareerr"
  },
  contentCreatorAcquisitionContentForm: {
    title: "Regístrese para iniciar",
    subtitle: "Completa este formulario y nos pondremos en contacto para los próximos pasos.",
    nameInput: "Nombre",
    namePlaceHolder: "Nombre",
    emailInput: "Email",
    emailPlaceHolder: "youremail@company.tld",
    contentTypeInput: "Tipo de contenido",
    contentTypeOptions: [
      {
        title: "Desarrollo de Software",
        isSelected: false,
      },
      {
        title: "Producto",
        isSelected: false,
      },
      {
        title: "Machine learning",
        isSelected: false,
      },
      {
        title: "Block Chain",
        isSelected: false,
      },
      {
        title: "Marketing",
        isSelected: false,
      },
      {
        title: "Otros",
        isSelected: false,
      }
    ],
    contentCreatorUrlInput: "Sitio Web / Enlace de la plataforma principal",
    contentCreatorUrlHolder: "https://kareerr.com/",
    allChannelsInput: "Canales",
    channelsOptions: [
      {
        title: "Youtube",
        isSelected: false,
      },
      {
        title: "Twitch",
        isSelected: false,
      },
      {
        title: "Linkedin",
        isSelected: false,
      },
      {
        title: "Instagram",
        isSelected: false,
      },
      {
        title: "Tiktok",
        isSelected: false,
      },
      {
        title: "Podcast",
        isSelected: false,
      },
      {
        title: "Other",
        isSelected: false,
      }
    ],
    ctaButtonTitle: "Enviar",
    ctaButtonPath: "/confirmation",
    enterpriseQuestion: "Eres una empresa?",
    enterpriseText: "Regístrate",
    enterpriseAcquisitionPath: "/hire-with-kareerr"
  },
  confirmationPage: {
    confirmationCardTitle: "¡Muchas gracias!",
    confirmationCards : [
      {
        id : "confirmation",
        imgUrl: "/images/confirmation/lyanna.png",
        name: "¡Tu solicitud ha sido enviada correctamente!",
        role: "Nos pondremos en contacto pronto",
        description: "Estamos encantados y con ganas de empezar a trabajar con vosotros :)"
      }
    ],
  },
  footerContent: {
    footerTitle: "Regístrate para recibir las últimas novedades y actualizaciones",
    footerSubtitle: "Puedes darte de baja en cualquier momento",
    footerEmailInputPlaceHolder: "Email",
    footerNameInputPlaceHolder: "Nombre",
    footerSubscribeButtonText: "Regístrate",
  },
  loginContent: {
    companyName: "KAREERR",
    loginEmailInput: "Email",
    loginEmailInputPlaceHolder: "youremail@company.tld",
    loginPwdInput: "Contraseña",
    loginPwdInputPlaceHolder: "*************",
    loginButtonText: "Iniciar sesión",
    loginForgotPwdText: "Te has olvidado la contraseña?",
    loginNoAccountText: "No tienes cuenta?",
    loginRegisterPath: "/register",
    loginRegisterText: "Regístrate",
  },
  enterpriseOfferJobsText: "Mis ofertas",
  searchJobsText: "Busca ofertas",
  addJobText: "Añadir Oferta",
  viewOfferDetailText: "Ver Oferta",
  newFormEnterpriseJobPage: {
    title: "Nueva Oferta",
    inputs: [
      {
        id: 1,
        data: "title",
        label: "Título",
        type: "text",
        placeholder: "IOS Software Engineer"
      },
      {
        id: 2,
        data: "conditions",
        label: "Condiciones",
        type: "text",
        placeholder: "Indefinido"
      },
      {
        id: 3,
        data: "location",
        label: "Localización",
        type: "text",
        placeholder: "Barcelona"
      },
      {
        id: 4,
        data: "description",
        label: "Descripción",
        type: "text",
        placeholder: "Descripción"
      },
      {
        id: 5,
        data: "referral",
        label: "Recompensa",
        type: "number",
        placeholder: "500€"
      }
    ],
    ctaText: "Añadir"
  },
}

export default SpanishContent;