import "./Sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon';
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link, useNavigate } from "react-router-dom";
import { DarkModeContext } from "../../context/DarkModeContext";
import React, { useContext } from "react";
import { useAuth } from "../../context/AuthContext";
import {LanguageContext} from "../../context/LanguageContext";

const EnterpriseSidebar = () => {
  const { content, dispatchLanguage } = useContext(LanguageContext);
  const { dispatch } = useContext(DarkModeContext);

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await logout();
      navigate("/dashboard");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
      <div className="sidebar">

        <div className="center">
          <ul>
            <p className="title">MENÚ</p>
            <Link to="/dashboard/enterprise" style={{ textDecoration: "none" }}>
              <li>
                <DashboardIcon className="icon" />
                <span>{content.dashboard}</span>
              </li>
            </Link>
            <Link to="/dashboard/enterprise/contributors" style={{ textDecoration: "none" }}>
              <li>
                <PersonOutlineIcon className="icon" />
                <span>{content.contributors}</span>
              </li>
            </Link>
            <Link to="/dashboard/enterprise/jobs" style={{ textDecoration: "none" }}>
              <li>
                <BusinessCenterIcon className="icon" />
                <span>{content.jobOffers}</span>
              </li>
            </Link>
            <Link to="/dashboard/enterprise/hiring-process" style={{ textDecoration: "none" }}>
              <li>
                <CatchingPokemonIcon className="icon" />
                <span>{content.hiringProcess}</span>
              </li>
            </Link>
            <li>
              <CreditCardIcon className="icon" />
              <span>{content.payments}</span>
            </li>
            <Link to="/dashboard/enterprise/profile" style={{ textDecoration: "none" }}>
              <li>
                <AccountCircleOutlinedIcon className="icon" />
                <span>{content.profile}</span>
              </li>
            </Link>
            <li>
              <div onClick={handleLogout}>
                <ExitToAppIcon className="icon"/>
                <span>{content.logout}</span>
              </div>

            </li>
          </ul>
        </div>
      </div>
  );
};

export default EnterpriseSidebar;