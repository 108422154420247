import Navbar from "../../components/Navbar";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import JobsCustomTable from "../../components/table/JobsCustomTable";
import React, {useEffect, useState} from "react";
import EnterpriseSidebar from "../../components/sidebars/EnterpriseSidebar";
import EnterpriseWidgetFactory
  from "../../components/widget/EnterpriseWidgetFactory";
import {useAuth} from "../../context/AuthContext";

const EnterpriseDashboard = () => {

  const [metrics, setMetrics] = useState();

  const { authUser, userInfo } = useAuth();

  useEffect(() => {
    const fetchMetrics = async () => {
      const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/getEnterpriseDashboardData',
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + authUser.accessToken,
              "Access-Control-Allow-Origin" : "*"
            },
            method: "POST",
            body: JSON.stringify({
              enterpriseId: userInfo.enterpriseAccount.enterpriseId
            })
          });
      const jsonResult = await result.json();
      setMetrics(jsonResult)
    }

    fetchMetrics().then(result => console.log("downloaded data " + result));
  }, []);

  return (
      <div className="dashboard">
        <Navbar/>
        <div className="dashboard-container">
          <EnterpriseSidebar />
          <div className="dashboard-body">
            <div className="widgets">
              <EnterpriseWidgetFactory type="visits" metrics={metrics}/>
              <EnterpriseWidgetFactory type="hiring-processes" metrics={metrics} />
              <EnterpriseWidgetFactory type="hiring" metrics={metrics}/>
              <EnterpriseWidgetFactory type="contributors" metrics={metrics}/>
            </div>
            <div className="charts">
              <Featured />
              <Chart title="Last 6 Months (Revenue)" aspect={2} chartData={metrics && metrics.mapVisitEventsPerMonth} />
            </div>
            <div className="listContainer">
              <div className="listTitle">Job board</div>
              <JobsCustomTable />
            </div>
          </div>
        </div>
      </div>
      );
};

export default EnterpriseDashboard;