import React, {useState} from "react";
import FileUpload from "./FileUpload";
import FileList from "./FileList";

const FileManager = ({offerJobId}) => {

  const [files, setFiles] = useState([])

  console.log(offerJobId);

  const removeFile = (filename) => {
    setFiles(files.filter(file => file.name !== filename))
  }

  return (
      <div>
        <FileUpload files={files} setFiles={setFiles}
                    removeFile={removeFile} offerJobId={offerJobId}/>
        <FileList files={files} removeFile={removeFile} />
      </div>

  );
}

export default FileManager;