import React from "react";
import "./CardCollection.scss"
import CardCollectionRow from "./CardCollectionRow";
import "./CardCollectionTable.scss";

const CardCollectionTable = ({type = "", cardsRows}) => {

  const CLASS_NAME = "collection-row-width-";

  return (
      <div className="card-collection-table">
        {
          cardsRows?.map((cardRow, index) => {
            return (
                <div key={index} className={"card-collection-table-row"+ " " + CLASS_NAME + (33*cardRow.length)}>
                  <CardCollectionRow type={type} cards={cardRow} />
                </div>
            )
          })
        }
      </div>
  )
}

export default CardCollectionTable;