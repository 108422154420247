import "./DetailText.scss";

const DetailText = ({title, data}) => {

    console.log(data);

  return (
      <div className="details">
        <h1 className="itemTitle">{title}</h1>
        {data?.map((info) => (
            <div key={info.key} className="detailItem">
              <span className="itemKey">{info.key}:</span>
              <span className="itemValue">{Array.isArray(info.value) ? "Array" : info.value}</span>
            </div>
        ))}
      </div>
  );
}

export default DetailText;