
export const mapJsonToDictionary = (jsonObject) => {
  let dictionary = [];
  for (let key in jsonObject) {
    dictionary.push({
      key : key,
      value : jsonObject[key]
    });
  }
  return dictionary;
}