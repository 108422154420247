import React from "react";
import "./JobPositionBody.scss"

const JobPositionBody = ({title, text}) => {

  return (
      <div className="job-body-item-info">
        <h3 className="job-body-item-title">
              {title}
        </h3>
        <p className="job-body-item-text">
              {text}
        </p>
      </div>
  )
}

export default JobPositionBody;