import React, {useContext} from "react";
import Footer from "../../components/footer/Footer";
import {LanguageContext} from "../../context/LanguageContext";
import ContentCreatorAcquisitionForm
  from "../../components/forms/content-creator/ContentCreatorAcquisitionForm";
import Navbar from "../../components/Navbar";

const ContentCreatorAcquisition = () => {

  const { content, dispatchLanguage } = useContext(LanguageContext);

  return (
      <div className="company-acquisition-container">
        <Navbar/>
        <ContentCreatorAcquisitionForm contentForm={content.contentCreatorAcquisitionContentForm}/>
        <Footer/>
      </div>
  )
}

export default ContentCreatorAcquisition;