import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Alert } from "./Alert";

function Register() {

    const { signup } = useAuth();

    const [user, setUser] = useState({
        email: "",
        password: "",
        name: "",
        lastName: ""
    });

    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        try {
            await signup(user);
            navigate("/dashboard");
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="w-full pt-32 max-w-md m-auto text-black">

            <div className="login-register-company-logo">
                <i className="login-register-logo-icon" />
                <p className="login-register-company-name">KAREERR</p>
            </div>

            {error && <Alert message={error} />}

            <form
                onSubmit={handleSubmit}
                className="bg-white shadow-md rounded px-8 pt-6 pb-6 mb-4"
            >
                <div className="mb-4">
                    <label
                        htmlFor="email"
                        className="block text-gray-800 text-sm font-bold mb-2"
                    >
                        Email
                    </label>
                    <input
                        type="email"
                        onChange={(e) => setUser({ ...user, email: e.target.value })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="youremail@company.tld"
                    />
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="password"
                        className="block text-gray-800 text-sm font-bold mb-2"
                    >
                        Password
                    </label>
                    <input
                        type="password"
                        onChange={(e) => setUser({ ...user, password: e.target.value })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="*************"
                    />
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="name"
                        className="block text-gray-800 text-sm font-bold mb-2"
                    >
                        Name
                    </label>
                    <input
                        type="text"
                        onChange={(e) => setUser({ ...user, name: e.target.value })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Name"
                    />
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="lastName"
                        className="block text-gray-800 text-sm font-bold mb-2"
                    >
                        Last Name
                    </label>
                    <input
                        type="text"
                        onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Last Name"
                    />
                </div>

                <div className="flex items-center justify-center py-4">
                    <button
                        className="bg-sky-600 hover:bg-sky-800 text-white w-full rounded-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                    >
                        Register
                    </button>
                </div>
            </form>
            <p className="my-4 text-sm flex justify-between px-3">
                Already have an Account?
                <Link to="/login" className="text-sky-800 hover:text-sky-900">
                    Login
                </Link>
            </p>
        </div>
    );
}

export default Register;