import "./List.scss";
import ContentCreatorSidebar from "../../components/sidebars/ContentCreatorSidebar";
import ProductCustomTable from "../../components/table/ProductCustomTable";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../context/AuthContext";
import Navbar from "../../components/Navbar";

const TableList = () => {

  const [rows, setRows] = useState();

  const { authUser } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch('https://europe-west6-faicing.cloudfunctions.net/getAllProducts',
          {
            headers: {
              'Authorization': 'Bearer ' + authUser.accessToken
            }
          });
      const jsonResult = await result.json();

      setRows(jsonResult);
    }

    fetchData().then(result => console.log("downloaded " + result));
  }, []);

  return (
      <div className="list">
        <Navbar />
        <div className="list-container">
          <ContentCreatorSidebar />
          <div className="list-body">
            <ProductCustomTable dataInput={rows}/>
          </div>
        </div>
      </div>
  )
}

export default TableList;