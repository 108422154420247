import "./Datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns, userRows } from "../../mocks/datatablesource";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import {useAuth} from "../../context/AuthContext";
import SearchBar from "../searchbar/SearchBar";

const Datatable = () => {

    const [data, setData] = useState(userRows);
    const [columns, setColumns] = useState(userColumns);
    const [rows, setRows] = useState(userRows);

    const { authUser } = useAuth();

    useEffect(() => {
      const fetchData = async () => {
        const result = await fetch('https://europe-west6-kareerr-7415.cloudfunctions.net/usersMocks',
            {
              headers: {
                'Authorization': 'Bearer ' + authUser.accessToken
              }
            });
        const jsonResult = await result.json();

        setData(jsonResult.rows);
        setColumns(jsonResult.columns);
        setRows(jsonResult.rows);
      }

      fetchData().then(result => console.log("downloaded " + result));
    }, []);

    const requestSearch = (searchedVal) => {
      const filteredData = data.filter((row) => {
        return row.username.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setRows(filteredData);
    };

    const cancelSearch = () => {
      requestSearch("");
    };

    const handleDelete = (id) => {
      console.log(id);
        setData(data.filter((item) => item.id !== id));
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <Link to={params.row.username} style={{ textDecoration: "none" }}>
                            <div className="viewButton">View</div>
                        </Link>
                        <div
                            className="deleteButton"
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];
    return (
        <div className="datatable">
            <div className="datatableTitle">
                Users
            </div>
            <div className="datatableFilters">
              <SearchBar placeHolder="Find User" searchAction={requestSearch} clearAction={cancelSearch} />
              <Link to="new" className="link">
                Add New
              </Link>
            </div>

            <DataGrid
                className="dataGrid"
                rows={rows}
                columns={columns.concat(actionColumn)}
                pageSize={9}
                rowsPerPageOptions={[9]}
                checkboxSelection
            />
        </div>
    );
};

export default Datatable;