import {Navigate} from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export function ProtectedRoute({level, children }) {

    const { authUser, userInfo, loading } = useAuth();

    const oldPath = window.location.href;

    if (loading) return <h1>Loading</h1>;

    if (!authUser) return <Navigate to={"/login?" + oldPath} />;

    if (!userInfo) return <Navigate to="/login" />;

    if (level === "ContentCreator" && !userInfo.contentCreatorAccount) return <Navigate to="/" />;

    if (level === "Enterprise" && !userInfo.enterpriseAccount) return <Navigate to="/" />;

    if (userInfo && !userInfo.active) return <Navigate to="/account-register" />;

    return <>{children}</>;
}