import "./CardSlider.scss";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import React from "react";
import SlideCardFactory from "../cards/SlideCardFactory";

const CardSlider = ({slideCardType, slideScrollSize, sliderTitle, cardSlides}) => {

  const scrollWidth = slideScrollSize ? slideScrollSize : 260;

  const slideLeft = () => {
    let slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 260;
  }

  const slideRight = () => {
    let slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 260;
  }

  return (
      <div className="card-slider-container">
        <div className="sliderTitle">
          {sliderTitle ? sliderTitle : "Recommended Products"}
        </div>
        <div className="card-slider-wrapper">
          <MdChevronLeft className="slider-icon left-arrow-icon" onClick={slideLeft}/>
          <div id="slider" className="slider">
            {
              cardSlides?.map((slide, index) => {
                return (
                    <SlideCardFactory key={index} type={slideCardType} slide={slide} />
                )
              })
            }
          </div>
          <MdChevronRight className="slider-icon right-arrow-icon" onClick={slideRight}/>
        </div>
      </div>

  );
}

export default CardSlider;