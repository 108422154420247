import React, {useContext} from "react";
import "./Home.scss";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footer/Footer";
import {LanguageContext} from "../../context/LanguageContext";
import TitledWidgetWithButton
  from "../../components/widget/TitledWidgetWithButton";
import CenteredCardSliderContainer
  from "../../components/slider/CenteredCardSliderContainer";

const Home = () => {

  const { content, dispatchLanguage } = useContext(LanguageContext);

    return (
        <div className="home-container">
          <Navbar/>
          <TitledWidgetWithButton data={content.homeWidget} />
          <CenteredCardSliderContainer slideCardType={"imageSlide"} sliderTitle={content.homeSlideCompaniesTitle} cardSlides={content.homeContentCompaniesSlides}/>
          <CenteredCardSliderContainer slideCardType={"withTitle"} sliderTitle={content.homeSlideContentCreatorTitle} cardSlides={content.homeContentCreatorSlides}/>
          <Footer/>
        </div>
    )

}

export default Home;