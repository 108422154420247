import React, {useContext} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Navbar from "../../components/Navbar";
import TitledWidgetWithButton
  from "../../components/widget/TitledWidgetWithButton";
import CenteredCardSliderContainer
  from "../../components/slider/CenteredCardSliderContainer";
import Footer from "../../components/footer/Footer";

const ContentCreatorHome = () => {

  const { content, dispatchLanguage } = useContext(LanguageContext);

  return (
      <div className="home-container">
        <Navbar/>
        <TitledWidgetWithButton data={content.creatorContentHomeWidget} />
        <CenteredCardSliderContainer slideCardType={"withTitle"} sliderTitle={content.homeSlideContentCreatorTitle} cardSlides={content.homeContentCreatorSlides}/>
        <CenteredCardSliderContainer slideCardType={"imageSlide"} sliderTitle={content.homeSlideCompaniesTitle} cardSlides={content.homeContentCompaniesSlides}/>
        <Footer/>
      </div>
  )

}

export default ContentCreatorHome;