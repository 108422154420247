import "./JobsCustomTable.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {Link} from "react-router-dom";
import SearchBar from "../searchbar/SearchBar";
import {useEffect, useState} from "react";
import {useAuth} from "../../context/AuthContext";

const headers = [
    "Nombre", "Empresa", "Role", "Condiciones", "Localidad", "Action"
];

const productRows = [
    {
        jobId: "03d60861-2b0e-4878-a7de-0c32460de7c8",
        companyName: "eDreams",
        imageUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/EDreams_ODIGEO_logo.svg/1600px-EDreams_ODIGEO_logo.svg.png",
        positionTitle: "Backend Architect - Technology",
        contractConditions: "Permanent",
        location: "Barcelona",
    },
    {
        jobId: "def5223f-7627-4cc1-b82c-eccfa6f5111d",
        companyName: "Wallapop",
        imageUrl: "https://ship-quik.com/wp-content/uploads/wallapop.png",
        positionTitle: "iOS Engineer",
        contractConditions: "Permanent",
        location: "Madrid",
    },
];

const JobsCustomTable = () => {

    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    const { authUser } = useAuth();

    useEffect(() => {
        const fetchSchemaData = async () => {
            const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/getJobTableSchemaData',
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + authUser.accessToken,
                        "Access-Control-Allow-Origin" : "*"
                    },
                    method: "POST"
                });
            return await result.json();
        };

        fetchSchemaData().then(result => {
            console.log(result)
            setColumns(result.columns);
            setData(result.rows);
            setRows(result.rows);
        });
    }, []);

    const requestSearch = (searchedVal) => {
        const filteredData = data.filter((row) => {
            return row.positionTitle.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setRows(filteredData);
    };

    const cancelSearch = () => {
        requestSearch("");
    };

    return (
        <div className="datatable">
            <div className="datatableTitle">
                Jobs
            </div>
            <div className="datatableFilters">
                <SearchBar placeHolder="Find Jobs" searchAction={requestSearch} clearAction={cancelSearch} />
                <Link to="new" className="link">
                    Add New
                </Link>
            </div>
            <TableContainer component={Paper} className="table">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow key="tableHeaders">
                            {columns?.map((headerTitle, index) => (
                                <TableCell key={index} className="tableCell">{headerTitle}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell className="tableCell">{row.enterprise.name}</TableCell>
                                <TableCell className="tableCell">
                                    <div className="cellWrapper">
                                        <img src={row.enterprise.logoUrl} alt="" className="image" />
                                        {row.enterprise.name}
                                    </div>
                                </TableCell>
                                <TableCell className="tableCell">{row.positionTitle}</TableCell>
                                <TableCell className="tableCell">{row.contractConditions}</TableCell>
                                <TableCell className="tableCell">{row.location}</TableCell>
                                <TableCell className="tableCell">
                                    <div className="cellAction">
                                        <Link to={"jobs/" + row.jobId} style={{ textDecoration: "none" }}>
                                            <div className="viewButton">View</div>
                                        </Link>
                                    </div>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default JobsCustomTable;