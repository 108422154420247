import React, {useState, useEffect, useContext} from "react";
import {Link, useNavigate} from "react-router-dom";
import './Navbar.scss';
import {Button} from "./buttons/Button";
import {useAuth} from "../context/AuthContext";
import {MenuItem, Select} from "@mui/material";
import {LanguageContext} from "../context/LanguageContext";

function Navbar() {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const { language, content, dispatchLanguage } = useContext(LanguageContext);

    const { authUser, logout, profileImage } = useAuth();

    const navigate = useNavigate();

    const handleClick = () => setClick(!click);

    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
      if (window.innerWidth <= 960) {
          setButton(false);
      } else {
          setButton(true);
      }
    }

    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            await logout();
            navigate("/login");
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleLanguageChange = (event) => {
        dispatchLanguage({ language: event.target.value })
    }

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener("resize", showButton);

    return (
        <>
            <nav className="navbar">
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        <i className="logo-icon" />
                        <p className="company-name">{content.homeNavBarContent.companyName}</p>
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={ click ? "fas fa-times" : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? "nav-menu active" : 'nav-menu'}>
                        <li className="nav-item">
                            <Link to="/" className = "nav-links" onClick={closeMobileMenu}>
                                <p className="links">{content.homeNavBarContent.homeTab}</p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/content-creators" className = "nav-links" onClick={closeMobileMenu}>
                                <p className="links">{content.homeNavBarContent.contentCreatorTab}</p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/dashboard" className = "nav-links" onClick={closeMobileMenu}>
                                <p className="links">{content.homeNavBarContent.dashboardTab}</p>
                            </Link>
                        </li>
                        <li className="nav-item-mobile">
                            <Link to="/login" className = "nav-links-mobile" onClick={closeMobileMenu}>
                                Log in
                            </Link>
                        </li>
                        <li className="nav-language-item-mobile">

                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={language}
                                label="language"
                                onChange={handleLanguageChange}
                            >
                                <MenuItem value={"en"}>English</MenuItem>
                                <MenuItem value={"es"}>Español</MenuItem>
                            </Select>
                        </li>

                    </ul>
                    {button && !authUser && <Button buttonStyle="btn-outline-gradient" buttonSize="btn-rounded-medium">Log in</Button> }
                    {authUser && <div className="item" onClick={handleLogout}>
                        <img
                            src={profileImage}
                            alt=""
                            className="avatar"
                        />
                    </div>
                    }
                    <div className="nav-language-item">

                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={language}
                            label="language"
                            onChange={handleLanguageChange}
                        >
                            <MenuItem value={"en"}>English</MenuItem>
                            <MenuItem value={"es"}>Español</MenuItem>
                        </Select>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar