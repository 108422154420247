import React, {useEffect, useState} from "react";
import "./HiringProcesses.scss";
import Navbar from "../../../components/Navbar";
import {useAuth} from "../../../context/AuthContext";
import EnterpriseSidebar from "../../../components/sidebars/EnterpriseSidebar";
import HiringProcessSummary
  from "../../../components/hiring-process/HiringProcessSummary";

const HiringProcesses = () => {

  const { authUser, userInfo } = useAuth();
  const [data, setData] = useState([]);

  console.log(userInfo.enterpriseAccount.enterpriseId);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/getEnterpriseHiringProcesses',
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + authUser.accessToken,
              "Access-Control-Allow-Origin" : "*"
            },
            method: "POST",
            body: JSON.stringify({
              enterpriseId: userInfo.enterpriseAccount.enterpriseId
            })
          });
      const jsonResult = await result.json();
      console.log(jsonResult);
      setData(jsonResult)
    }

    fetchData().then(result => console.log("downloaded " + result));
    //fetchDataFromFunction().then(() => console.log(" Finished "))
  }, []);

  return (
      <div>
        <Navbar />
        <div className="hiring-processes-body-page">
          <div className="hiring-processes-container">
            <EnterpriseSidebar />
            <div className="hiring-processes-detailed-info-content">
              {
                data?.map((hiringProcess, index) => {
                  return <div className="hiring-process-detailed-info-container">
                    <HiringProcessSummary key={index} hiringProcess={hiringProcess} />
                  </div>
                })
              }
            </div>
          </div>
        </div>
      </div>

  );
}

export default HiringProcesses;