import "./Chart.scss";
import {
    AreaChart,
    Area,
    XAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import {useEffect, useState} from "react";

const mockData = [
    { name: "January", Total: 1200 },
    { name: "February", Total: 2100 },
    { name: "March", Total: 800 },
    { name: "April", Total: 1600 },
    { name: "May", Total: 900 },
    { name: "June", Total: 1700 },
];

const Chart = ({ aspect, title, chartData }) => {

    console.log(chartData);

    const getData = (chartData) => {
        console.log("ENTERING");
        let data = mockData;
        console.log(chartData);
        if (chartData) {
            const transformedData = Object.keys(chartData).map( (key) => {
                return {
                    name: key,
                    Total: chartData[key]
                }
            } );
            console.log(transformedData);
            data = transformedData;
        }
        return data;
    }

    return (
        <div className="chart">
            <div className="title">{title}</div>
            <ResponsiveContainer width="100%" aspect={aspect}>
                <AreaChart
                    width={730}
                    height={250}
                    data={getData(chartData)}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                    <defs>
                        <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#ff375f" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#ff375f" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" stroke="gray" />
                    <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
                    <Tooltip />
                    <Area
                        type="monotone"
                        dataKey="Total"
                        stroke="#ff375f"
                        fillOpacity={1}
                        fill="url(#total)"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default Chart;