import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import {useAuth} from "../../context/AuthContext";
import "./Product.scss";
import ContentCreatorSidebar from "../../components/sidebars/ContentCreatorSidebar";
import DashboardNavbar from "../../components/dashboard-navbar/DashboardNavbar";
import BarGraph from "../../components/chart/BarGraph";
import DetailText from "../../components/text/DetailText";
import JobsCustomTable from "../../components/table/JobsCustomTable";
import {mapJsonToDictionary} from "../../utils/utils";

const Product = () => {

  const { productId } = useParams();
  const [data, setData] = useState();

  const { authUser } = useAuth();

  console.log(data);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch('https://europe-west6-faicing.cloudfunctions.net/getProduct',
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + authUser.accessToken,
              "Access-Control-Allow-Origin" : "*"
            },
            method: "POST",
            body: JSON.stringify({
              productId: productId
            })
          });
      const jsonResult = await result.json();
      console.log(jsonResult);
      setData(jsonResult)
    }

    fetchData().then(result => console.log("downloaded " + result));
    //fetchDataFromFunction().then(() => console.log(" Finished "))
  }, []);

  const productDataMock = [
    {
      key : "Category",
      value : "Moda"
    },
    {
      key : "Stock",
      value : "21"
    },
    {
      key : "Price",
      value : "29.99€"
    },
    {
      key : "Total Sales",
      value : "1991"
    },
  ];

  return (
      <div className="product">
        <ContentCreatorSidebar />
        <div className="productContainer">
          <DashboardNavbar />
          <div className="top">
            <div className="left">
              <h1 className="productTitle">
                Product Information
              </h1>
              <div className="item">
                <img
                    src={data?.imgUrl}
                    alt=""
                    className="itemImg"
                />
                <DetailText title={data?.title} data={mapJsonToDictionary(data)}/>
              </div>
            </div>
            <div className="right">
              <BarGraph title="Sales" data="" />
            </div>
          </div>
          <div className="bottom">
            <h1 className="title">Last Transactions</h1>
            <JobsCustomTable/>
          </div>
        </div>
      </div>
  );
}

export default Product;