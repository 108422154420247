import React, {useContext, useState} from "react";
import "./AccountRegister.scss";
import {Alert} from "./Alert";
import {Link, useNavigate} from "react-router-dom";
import {LanguageContext} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";

const AccountRegister = () => {

    const accounts = [
        {
            title: "Content Creator",
            icon: "/images/register/creatorContent.png",
            isSelected: false,
        },
        {
            title: "Enterprise",
            icon: "/images/register/business.png",
            isSelected: false,
        },
        {
            title: "Professional",
            icon: "/images/register/professional.png",
            isSelected: false,
        }
    ];

    const [accountTypes, setAccountTypes] = useState(accounts);

    const { authUser, userInfo } = useAuth();

    const { content } = useContext(LanguageContext);

    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleAccountTypeOptionClick = (index) => {
        const isAccountSelected = !accountTypes[index].isSelected;
        accountTypes.forEach(accountType => accountType.isSelected = false);
        accountTypes[index].isSelected = isAccountSelected;
        setAccountTypes(Object.assign([], accountTypes));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        let accounts = accountTypes.filter(accountType => accountType.isSelected);
        if(accounts.length < 1) {
            setError("You must choose one option");
        } else {
            let account = accounts[0];
            switch (account.title) {
                case "Enterprise":
                    navigate("/hire-with-kareerr");
                    break;
                case "Content Creator":
                    navigate("/join-kareerr");
                    break;
                case "Professional":
                    await activateUser();
                    break;
                default:
                    break;
            }
        }
    };

    const activateUser = async () => {
        const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/updateUser',
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authUser.accessToken,
                    "Access-Control-Allow-Origin" : "*"
                },
                method: "POST",
                body: JSON.stringify({
                    userId: userInfo.userId,
                    active: true
                })
            });
        const jsonResult = await result.json();
        console.log(jsonResult);
        navigate("/dashboard");
    }

    return (
        <div className="w-full pt-32 max-w-xl m-auto text-black">

            <div className="account-options-header-container">
                <h1 className="account-options-header-title">{content.contentCreatorAcquisitionContentForm.title}</h1>
                <h2 className="account-options-header-subtitle">{content.contentCreatorAcquisitionContentForm.subtitle}</h2>
            </div>

            {error && <Alert message={error} />}

            <form onSubmit={handleSubmit}
                  className="bg-white shadow-md rounded px-8 pt-6 pb-6 mb-4">
                <div className="py-4">
                        <span className="text-gray-700 ">Tipo de Account:</span>
                </div>

                <div className="account-options-container">
                    {
                        accountTypes?.map((accountType, index) => {
                            return <div key={accountType.title}>
                                <div role="button" className={"account-options account-options" + (accountTypes[index].isSelected ? "-selected" : "-unselected")}
                                     onClick={() => handleAccountTypeOptionClick(index)}>
                                    <img className="account-options-image" src={accountType.icon} alt=""/>
                                    <span className="account-options-text">{accountType.title} </span>
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className="flex items-center justify-center py-4">
                    <button
                        className="bg-sky-600 hover:bg-sky-800 text-white w-full rounded-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                    >
                        Register
                    </button>
                </div>
            </form>

        </div>
    );
}

export default AccountRegister;