import ProgressTracker from "../progress-tracker/ProgressTracker";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../context/AuthContext";
import "./HiringProcessSummary.scss";
import {Link} from "react-router-dom";

const HiringProcessSummary = ({hiringProcess}) => {

  const { authUser } = useAuth();

  const [candidate, setCandidate] = useState();
  const [jobData, setJobData] = useState();

  useEffect(() => {
    const fetchCandidateData = async () => {
      const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/getUserById',
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + authUser.accessToken,
              "Access-Control-Allow-Origin" : "*"
            },
            method: "POST",
            body: JSON.stringify({
              userId : hiringProcess.professionalCandidateId
            })
          });
      const jsonResult = await result.json();
      console.log(jsonResult);
      setCandidate(jsonResult)
    }

    const fetchJobData = async () => {
      const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/getJobById',
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + authUser.accessToken,
              "Access-Control-Allow-Origin" : "*"
            },
            method: "POST",
            body: JSON.stringify({
              jobId: encodeURIComponent(hiringProcess.jobId)
            })
          });
      const jsonResult = await result.json();
      console.log(jsonResult);
      setJobData(jsonResult)
    }

    fetchCandidateData().then(result => console.log("downloaded Candidate Info" + result));
    fetchJobData().then(result => console.log("downloaded Job Info " + result));
    //fetchDataFromFunction().then(() => console.log(" Finished "))
  }, []);

  return (
      <div className="hiring-process-detail-container">
        <div className="hiring-process-detail-info-container">
          <img src={"/images/grison.jpeg"} alt="" className="candidate-rounded-image"/>
          <div className="candidate-job-text-container">
            <h5 className="hiring-process-candidate-name">{candidate && candidate.name}</h5>
            <span className="candidate-job-text">{jobData && jobData.positionTitle}</span>
          </div>
        </div>
        <div>
          <ProgressTracker status={hiringProcess.hiringStatus}/>
        </div>
        <div className="flex items-center justify-center pt-8 pb-4 text-center">
          <Link
              className="bg-sky-600 hover:bg-sky-800 text-white w-1/5 rounded-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              to="jioavanviv332"
          >
            {"BUTTON"}
          </Link>
        </div>
      </div>
      )
}

export default HiringProcessSummary;