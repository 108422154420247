import { createContext, useContext, useEffect, useState } from "react";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase";
import {getStorage, getDownloadURL, ref} from "firebase/storage";

const authContext = createContext();

export const useAuth = () => {
    const context = useContext(authContext);
    if (!context) throw new Error("There is no Auth provider");
    return context;
};

export function AuthProvider({ children }) {
    const [authUser, setAuthUser] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [profile, setProfile] = useState();
    const [profileImage, setProfileImage] = useState("/images/grison.jpeg");
    const [loading, setLoading] = useState(true);

    const storage = getStorage();

    const signup = async (user) => {
      let promise = await createUserWithEmailAndPassword(auth, user.email, user.password);
      let request = buildUserRequest(promise.user.uid, user);
      await pushUserAccountData(promise.user.accessToken,
          request);
    };

    const login = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    };

    const logout = () => signOut(auth);

    const resetPassword = async (email) => sendPasswordResetEmail(auth, email);

    const fetchUserAccountData = async (userAccessToken, userId) => {
        console.log(userId);
        const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/getUserById',
            {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + userAccessToken,
                  "Access-Control-Allow-Origin" : "*"
                },
                method: "POST",
                body: JSON.stringify({
                  userId : userId
                })
            });
        const jsonResult = await result.json();
        console.log(jsonResult);
        setUserInfo(jsonResult);
    }

    const getUserProfile = async () => {
      const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/getUserProfile',
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + authUser.accessToken,
              "Access-Control-Allow-Origin" : "*"
            },
            method: "POST"
          });
      const jsonResult = await result.json();
      console.log(jsonResult);
      await downloadUserProfileImage(jsonResult.profileImg)
      setProfile(jsonResult);
    }

  const downloadUserProfileImage = async (imagePath) => {

    getDownloadURL(ref(storage, imagePath))
        .then((url) => {
          setProfileImage(url);
        })
        .catch((error) => {
          // Handle any errors
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              console.log("NOT FOUND");
              break;
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              console.log("unauthorized");
              break;
            case 'storage/canceled':
              // User canceled the upload
              console.log("canceled");
              break;
            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              console.log("unknown");
              break;
          }
        });
  }

  const buildUserRequest = (userId, user) => {
      return {
        userId : userId,
        email : user.email,
        name : user.name,
        lastName : user.lastName
      }
    };

    const pushUserAccountData = async (userAccessToken, userRequest) => {
      const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/createUser',
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + userAccessToken,
              "Access-Control-Allow-Origin" : "*"
            },
            method: "POST",
            body: JSON.stringify(userRequest)
          });
      const jsonResult = await result.json();
      console.log(jsonResult);
      setUserInfo(jsonResult);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
          console.log("CURRENT USER");
          console.log(currentUser);
            if (currentUser) {
              console.log("fetching userAccount");
              fetchUserAccountData(currentUser.accessToken, currentUser.uid).then(result => console.log("USER MODEL FETCHED"));
              getUserProfile(currentUser.uid).then(result => console.log("USER Profile FETCHED"));
            }
            setAuthUser(currentUser);
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    return (
        <authContext.Provider
            value={{
                signup,
                login,
                logout,
                authUser,
                userInfo,
                profile,
                profileImage,
                loading,
                resetPassword,
            }}
        >
            {children}
        </authContext.Provider>
    );
}