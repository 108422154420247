import {useAuth} from "../../context/AuthContext";
import { Navigate } from "react-router-dom";

const DashboardFactory = () => {

  const { userInfo } = useAuth();

  const createDashboard = () => {
    if(userInfo.contentCreatorAccount) {
      return (<Navigate to="/dashboard/content-creator" />);
    }

    if(userInfo.enterpriseAccount) {
      return (<Navigate to="/dashboard/enterprise" />);
    }

    if(userInfo && userInfo.active) {
      return (<Navigate to="/dashboard/professional" />);
    }

    return (<h1>>ERROR</h1>);
  }

  return (
      <>
        {userInfo && createDashboard()}
      </>
  );

}

export default DashboardFactory;