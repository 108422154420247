import HiringProcessSummary from "./HiringProcessSummary";
import React from "react";

const HiringProcessDetails = ({hiringProcess}) => {

  return (
      <div>
        <h1>HOLAA</h1>
      </div>

  );
}

export default HiringProcessDetails;