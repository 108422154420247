import CardText from "./CardText";
import RoundedImageCard from "./RoundedImageCard";
import Card from "./Card";
import React from "react";
import OfferJobCard from "./OfferJobCard";

const CardFactory = ({type, card}) => {

  const createCard = () => {
    switch (type) {
      case "withText":
        return (
            <CardText title={card.title} text={card.text} />
        );
      case "withRoundedImage":
        return (
            <RoundedImageCard
                key={card.id}
                imgUrl={card.imgUrl}
                title={card.name}
                subtitle={card.role}
                text={card.description}
            />
        );
      case "roundedJobsCard":
        return (
            <OfferJobCard
                key={card.jobId}
                offerId={card.jobId}
                imgUrl={card.enterprise.logoUrl}
                title={card.positionTitle}
                subtitle={card.contractConditions}
                text={card.location}
            />
        );
      default:
        return (
            <Card key={card.id}
                  url={card.url}
                  imgUrl = {card.imgUrl}
                  title = {card.title}
                  text = {card.text}/>
        );
    }
  }

  return (
      createCard(type, card)
  );
}

export default CardFactory;