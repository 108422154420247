import Navbar from "../../../components/Navbar";
import ContentCreatorSidebar
  from "../../../components/sidebars/ContentCreatorSidebar";
import React from "react";
import Profile from "../../../components/profile/Profile";

const ContentCreatorProfilePage = () => {

  return (
      <div className="job-list">
        <Navbar />
        <div className="job-list-container">
          <ContentCreatorSidebar />
          <div className="job-list-body">
            <Profile />
          </div>
        </div>
      </div>
  )

}

export default ContentCreatorProfilePage;