import "./TitleWidgetWithButton.scss"
import { Link } from "react-router-dom";

const TitledWidgetWithButton = ({ data }) => {

  console.log(data);

  return (
      <div className="titled-button-widget-container">
        <div className="titled-button-widget-text-container">
          <h1 className="titled-button-widget-title">{data.title}</h1>
          <h2 className="titled-button-widget-subtitle">{data.subtitle}</h2>
        </div>
        <Link to={data.ctaUrl} className="titled-button-widget-cta-button">
          {data.ctaButtonText}
        </Link>
      </div>
  );
};

export default TitledWidgetWithButton;