import "./Dashboard.scss";
import WidgetFactory from "../../components/widget/WidgetFactory";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import JobsCustomTable from "../../components/table/JobsCustomTable";
import Navbar from "../../components/Navbar";
import React from "react";
import ProfessionalSidebar from "../../components/sidebars/ProfessionalSidebar";

const ProfessionalDashboard = () => {
  return (
      <div className="dashboard">
        <Navbar/>
        <div className="dashboard-container">
          <ProfessionalSidebar />
          <div className="dashboard-body">
            <div className="widgets">
              <WidgetFactory type="user" />
              <WidgetFactory type="order" />
              <WidgetFactory type="earning" />
              <WidgetFactory type="balance" />
            </div>
            <div className="charts">
              <Featured />
              <Chart title="Last 6 Months (Revenue)" aspect={2} />
            </div>
            <div className="listContainer">
              <div className="listTitle">Job board</div>
              <JobsCustomTable />
            </div>
          </div>
        </div>
      </div>
  );
};

export default ProfessionalDashboard;