import React, {useContext} from 'react';
import './Footer.scss';
import { Button } from '../buttons/Button';
import { Link } from 'react-router-dom';
import {LanguageContext} from "../../context/LanguageContext";

const Footer = () => {

  const { content } = useContext(LanguageContext);

  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          {content.footerContent.footerTitle}
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
          {content.footerContent.footerSubtitle}
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder={content.footerContent.footerEmailInputPlaceHolder}
            />
            <input
                className='footer-input'
                name='name'
                type='text'
                placeholder={content.footerContent.footerNameInputPlaceHolder}
            />
            <Button buttonStyle='btn--outline'>{content.footerContent.footerSubscribeButtonText}</Button>
          </form>
        </div>
      </section>

      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              Kareerr
              <i className='footer-logo-icon' />
            </Link>
          </div>
          <small className='website-rights'>Kareerr © 2023</small>
          <div className='social-icons'>
            <Link
                className='social-icon-link facebook'
                to='/'
                target='_blank'
                aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </Link>
            <Link
                className='social-icon-link instagram'
                to='/'
                target='_blank'
                aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </Link>
            <Link
                className='social-icon-link youtube'
                to='/'
                target='_blank'
                aria-label='Youtube'
            >
              <i className='fab fa-youtube' />
            </Link>
            <Link
                className='social-icon-link twitter'
                to='/'
                target='_blank'
                aria-label='Twitter'
            >
              <i className='fab fa-twitter' />
            </Link>
            <Link
                className='social-icon-link twitter'
                to='/'
                target='_blank'
                aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
