import React from "react";
import "./JobPositionSkills.scss";

const JobPositionSkills = ({title, skills}) => {

  return (
      <div className="job-position-skills-card-container">
        <div className="job-position-skills-card-box">
          <div className="job-position-skills-title-container">
            <p className="job-position-skills-title">{title}</p>
          </div>
          <div className="job-position-skills-tag-container" >
            {
              skills?.map((skill) => {
                return <div key={skill} className="job-position-skills-tag">
                  {skill}
                </div>
              })
            }
          </div>

        </div>
      </div>
  )
}

export default JobPositionSkills;