import "./Sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link, useNavigate } from "react-router-dom";
import { DarkModeContext } from "../../context/DarkModeContext";
import React, { useContext } from "react";
import { useAuth } from "../../context/AuthContext";
import {LanguageContext} from "../../context/LanguageContext";

const ContentCreatorSidebar = () => {
    const { content, dispatchLanguage } = useContext(LanguageContext);
    const { dispatch } = useContext(DarkModeContext);

    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            await logout();
            navigate("/login");
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <div className="sidebar">

            <div className="center">
                <ul>
                    <p className="title">MENÚ</p>
                    <Link to="/dashboard/content-creator" style={{ textDecoration: "none" }}>
                        <li>
                            <DashboardIcon className="icon" />
                            <span>{content.dashboard}</span>
                        </li>
                    </Link>
                    <Link to="/dashboard/content-creator/customers" style={{ textDecoration: "none" }}>
                        <li>
                            <PersonOutlineIcon className="icon" />
                            <span>{content.customers}</span>
                        </li>
                    </Link>
                    <Link to="/dashboard/content-creator/jobs" style={{ textDecoration: "none" }}>
                        <li>
                            <BusinessCenterIcon className="icon" />
                            <span>{content.jobs}</span>
                        </li>
                    </Link>
                    <li>
                        <CreditCardIcon className="icon" />
                        <span>{content.payments}</span>
                    </li>
                    <Link to="/dashboard/content-creator/profile" style={{ textDecoration: "none" }}>
                        <li>
                            <AccountCircleOutlinedIcon className="icon" />
                            <span>{content.profile}</span>
                        </li>
                    </Link>
                    <li>
                        <div onClick={handleLogout}>
                            <ExitToAppIcon className="icon"/>
                            <span>{content.logout}</span>
                        </div>

                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ContentCreatorSidebar;