import "./NewJobOfferForm.scss";
import React, {useContext, useEffect, useState} from "react";
import EnterpriseSidebar from "../../../components/sidebars/EnterpriseSidebar";
import Navbar from "../../../components/Navbar";
import {LanguageContext} from "../../../context/LanguageContext";
import {useAuth} from "../../../context/AuthContext";

const NewJobOfferForm = () => {

    const [enterpriseData, setEnterpriseData] = useState({});

    const [jobTitle, setJobTitle] = useState("");
    const [conditions, setConditions] = useState("");
    const [location, setLocation] = useState("");
    const [description, setDescription] = useState("");
    const [referral, setReferral] = useState("");

    const [error, setError] = useState("");

    const { content } = useContext(LanguageContext);
    const { authUser, userInfo } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/getEnterpriseById',
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + authUser.accessToken,
                        "Access-Control-Allow-Origin" : "*"
                    },
                    method: "POST",
                    body: JSON.stringify({
                        enterpriseId: encodeURIComponent(userInfo.enterpriseAccount.enterpriseId)
                    })
                });
            return await result.json();
        }
        fetchData().then(result => setEnterpriseData(result));
    }, []);

    const handleFormDataInput = (data, input) => {
        switch (data) {
            case "title":
                handleJobTitleInput(input);
                break;
            case "conditions":
                handleConditionsInput(input);
                break;
            case "location":
                handleLocationInput(input);
                break;
            case "description":
                handleDescriptionInput(input);
                break;
            case "referral":
                handleReferralInput(input);
                break;
            default:
                console.log("Wrong data type");
        }
    }

    const handleJobTitleInput = (titleInput) => {
        setJobTitle(titleInput);
    }

    const handleConditionsInput = (conditionInput) => {
        setConditions(conditionInput);
    }

    const handleLocationInput = (locationInput) => {
        setLocation(locationInput);
    }

    const handleDescriptionInput = (descriptionInput) => {
        setDescription(descriptionInput);
    }

    const handleReferralInput = (referralInput) => {
        setReferral(referralInput);
    }

    const validateForm = () => {
        if (jobTitle === "" || jobTitle === "undefined") {
            return "Title could not be empty";
        }
        if (conditions === "" || conditions === "undefined") {
            return "Your Conditions could not be empty";
        }
        if (location === "" || location === "undefined") {
            return "Your Location could not be empty";
        }
        if (description === "" || description === "undefined") {
            return "Your Location could not be empty";
        }
        if (referral === "" || referral === "undefined") {
            return "Your Referral could not be empty";
        }
        return "";
    }

    const buildFormData = () => {
        return  {
            enterpriseId : enterpriseData.enterpriseId,
            positionTitle: jobTitle,
            contractConditions: conditions,
            location: location,
            description: description,
            referralFeeAmount: referral
        };
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        let formError = validateForm();
        if (formError === "") {
            let formData = buildFormData();
            pushData(formData).then(result => {
                console.log("downloaded " + result);
                console.log("Job Created");
            });
        } else {
            setError(formError);
        }
    };

    const pushData = async (body) => {
        console.log(body);
        const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/createJob',
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authUser.accessToken,
                    "Access-Control-Allow-Origin" : "*"
                },
                method: "POST",
                body: JSON.stringify(body)
            });
        const jsonResult = await result.json();
        console.log(jsonResult);
        return jsonResult;
    }

    return (
        <div className="new-job-offer">
            <Navbar />
            <div className="new-job-offer-container">
                <EnterpriseSidebar />
                <div className="new-job-offer-body">
                    <div className="new-job-enterprise-logo">
                        <img
                            src={
                                enterpriseData
                                    ? enterpriseData.logoUrl
                                    : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                            }
                            alt=""
                        />
                    </div>
                    <div className="new-job-offer-title">
                        {content.newFormEnterpriseJobPage.title}
                    </div>
                    <div className="bottom">

                        <div className="right">
                            <form onSubmit={handleSubmit}>
                                <div className="new-job-offer-form">
                                    {content.newFormEnterpriseJobPage.inputs.map((input) => (
                                        <div className="formInput" key={input.id}>
                                            <label>{input.label}</label>
                                            <input type={input.type}
                                                   onChange={(e) => handleFormDataInput(input.data, e.target.value)}
                                                   placeholder={input.placeholder} />
                                        </div>
                                    ))}
                                </div>
                                <div className="new-job-offer-form-button-container">
                                    <button className="new-job-offer-form-button">
                                        {content.newFormEnterpriseJobPage.ctaText}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewJobOfferForm;