import React, { useState } from "react";
import "./SearchBar.scss";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const SearchBar= ({ placeHolder, searchAction, clearAction }) => {
  const [query, setQuery] = useState("");

  const handleQuery = (event) => {
    const queryFiltered = event.target.value;
    setQuery(queryFiltered);
    searchAction(queryFiltered);
  }

  const clearQuery = () => {
    setQuery("");
    clearAction();
  }

  return (
      <div className="search">
        <div className="searchInputs">
          <input
              type="text"
              placeholder={placeHolder}
              value={query}
              onChange={handleQuery}
          />
          <div className="searchIcon">
            {query === "" ? (
                <SearchIcon />
            ) : (
                <CloseIcon id="clearBtn" onClick={clearQuery} />
            )}
          </div>
        </div>
      </div>
  );
}

export default SearchBar;