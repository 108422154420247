import "./Single.scss";
import ContentCreatorSidebar from "../../components/sidebars/ContentCreatorSidebar";
import DashboardNavbar from "../../components/dashboard-navbar/DashboardNavbar";
import Chart from "../../components/chart/Chart";
import DetailText from "../../components/text/DetailText";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuth} from "../../context/AuthContext";
import {mapJsonToDictionary} from "../../utils/utils";
import ProductCustomTable from "../../components/table/ProductCustomTable";
import CardSlider from "../../components/slider/CardSlider";

const Single = () => {

    const { customerId } = useParams();
    const [data, setData] = useState({});
    const [recommendations, setRecommendations] = useState();

    const { authUser } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            const result = await fetch('https://europe-west6-faicing.cloudfunctions.net/getCustomer',
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + authUser.accessToken,
                        "Access-Control-Allow-Origin" : "*"
                    },
                    method: "POST",
                    body: JSON.stringify({
                        customerId: customerId
                    })
                });
            const jsonResult = await result.json();
            console.log(jsonResult);
            setData(jsonResult)
        }

        const fetchRecommendedProducts = async () => {
            const result = await fetch(`https://europe-west6-faicing.cloudfunctions.net/getCustomerRecommendations`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + authUser.accessToken,
                        "Access-Control-Allow-Origin" : "*"
                    },
                    method: "POST",
                    body: JSON.stringify({
                        customerId: customerId,
                        thresholdValue : 0.3,
                        customersToCompare : 2
                    })
                });
            const jsonResult = await result.json();
            console.log(jsonResult);
            setRecommendations(jsonResult.products)
        }

        fetchData().then(result => console.log("downloaded data " + result));
        fetchRecommendedProducts().then(result => console.log("downloaded recommendations " + result));
    }, []);

    const userDataMock = [
        {
            key : "Email",
            value : "janedoe@gmail.com"
        },
        {
            key : "Phone",
            value : "+1 2345 67 89"
        },
        {
            key : "Address",
            value : "Elton St. 234 Garden Yd. NewYork"
        },
        {
            key : "Country",
            value : "USA"
        },
    ];

    return (
        <div className="single">
            <ContentCreatorSidebar />
            <div className="singleContainer">
                <DashboardNavbar />
                <div className="top">
                    <div className="left">
                        <div className="editButton">Edit</div>
                        <h1 className="title">Information</h1>
                        <div className="item">
                            <img
                                src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                                alt=""
                                className="itemImg"
                            />
                            <DetailText title={(data && data.basicInfo) ? data.basicInfo.customerId : ""}
                                        data={mapJsonToDictionary(data.basicInfo)}/>
                        </div>
                    </div>
                    <div className="right">
                        <Chart aspect={3} title="User Spending ( Last 6 Months)" />
                    </div>
                </div>
                <div className="middle">
                    <CardSlider cardSlides={recommendations}/>
                </div>
                <div className="bottom">
                    <ProductCustomTable dataTitle="Other Products Bought"
                                 dataInput={data.products?.boughtOtherProducts}/>
                </div>
            </div>
        </div>
    );
};

export default Single;