import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import TableList from "./pages/list/TableList";

import { AuthProvider } from "./context/AuthContext";
import {ProtectedRoute} from "./router/ProtectedRoute";

import { productInputs, userInputs } from "./mocks/formSource";

import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/DarkModeContext";
import Product from "./pages/product/Product";
import Offer from "./pages/offers/Offer";
import CompanyAcquisition from "./pages/enterprise/CompanyAcquisition";
import ContentCreatorHome from "./pages/content-creators/ContentCreatorHome";
import ContentCreatorAcquisition
    from "./pages/content-creators/ContentCreatorAcquisition";
import Confirmation from "./pages/confirmation/Confirmation";
import DashboardFactory from "./pages/dashboards/DashboardFactory";
import ContentCreatorDashboard
    from "./pages/dashboards/ContentCreatorDashboard";
import EnterpriseDashboard from "./pages/dashboards/EnterpriseDashboard";
import CustomerList from "./pages/content-creators/customers/CustomerList";
import ContributorList from "./pages/enterprise/contributors/ContributorList";
import JobOffersList from "./pages/enterprise/job-offers/JobOffersList";
import JobList from "./pages/content-creators/jobs/JobList";
import PostedJob from "./pages/content-creators/jobs/PostedJob";
import JobOffer from "./pages/enterprise/job-offers/JobOffer";
import NewJobOfferForm from "./pages/enterprise/job-offers/NewJobOfferForm";
import {LanguageContextProvider} from "./context/LanguageContext";
import AccountRegister from "./pages/AccountRegister";
import ProfessionalDashboard from "./pages/dashboards/ProfessionalDashboard";
import HiringProcesses from "./pages/enterprise/hiring-process/HiringProcesses";
import EnterpriseProfilePage
    from "./pages/enterprise/profile/EnterpriseProfilePage";
import ProfessionalProfilePage
    from "./pages/professionals/profile/ProfessionalProfilePage";
import ContentCreatorProfilePage
    from "./pages/content-creators/profile/ContentCreatorProfilePage";
import HiringProcessDetails
    from "./components/hiring-process/HiringProcessDetails";

function App() {

  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className={darkMode ? "app dark" : "app"}>
        <Router>
            <AuthProvider>
                <LanguageContextProvider>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/login' element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/account-register" element={<AccountRegister />} />
                        <Route path="/content-creators" element={<ContentCreatorHome />} />
                        <Route path="/offers">
                            <Route path=":token" element={
                                <Offer />
                            } />
                        </Route>
                        <Route path="/hire-with-kareerr" element={<CompanyAcquisition />}/>
                        <Route path="/join-kareerr" element={<ContentCreatorAcquisition />}/>
                        <Route path='/confirmation' element={<Confirmation />} />
                        <Route path="/dashboard">
                            <Route index element={
                                <ProtectedRoute >
                                    <DashboardFactory />
                                </ProtectedRoute>
                            } />
                        </Route>
                        <Route path="/dashboard/content-creator">
                            <Route index element={
                                <ProtectedRoute level="ContentCreator">
                                    <ContentCreatorDashboard />
                                </ProtectedRoute>
                            } />
                            <Route path="customers">
                                <Route index element={
                                    <ProtectedRoute>
                                        <CustomerList />
                                    </ProtectedRoute>
                                } />
                                <Route path=":customerId" element={
                                    <ProtectedRoute>
                                        <Single />
                                    </ProtectedRoute>
                                } />
                                <Route
                                    path="new"
                                    element={
                                        <ProtectedRoute>
                                            <New inputs={userInputs} title="Add NewJobOfferForm User" />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path="products">
                                <Route index element={
                                    <ProtectedRoute level={"ContentCreator"}>
                                        <TableList />
                                    </ProtectedRoute>
                                } />
                                <Route path=":productId" element={
                                    <ProtectedRoute>
                                        <Product />
                                    </ProtectedRoute>
                                } />
                                <Route
                                    path="new"
                                    element={
                                        <ProtectedRoute>
                                            <New inputs={productInputs} title="Add NewJobOfferForm Product" />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path="jobs">
                                <Route index element={
                                    <ProtectedRoute>
                                        <JobList />
                                    </ProtectedRoute>
                                } />
                                <Route path=":jobId" element={
                                    <ProtectedRoute>
                                        <PostedJob />
                                    </ProtectedRoute>
                                } />
                            </Route>
                            <Route path="profile">
                                <Route index element={
                                    <ProtectedRoute>
                                        <ContentCreatorProfilePage />
                                    </ProtectedRoute>
                                } />
                            </Route>
                        </Route>
                        <Route path="/dashboard/enterprise">
                            <Route index element={
                                <ProtectedRoute level="Enterprise">
                                    <EnterpriseDashboard />
                                </ProtectedRoute>
                            } />
                            <Route path="contributors">
                                <Route index element={
                                    <ProtectedRoute level="Enterprise">
                                        <ContributorList />
                                    </ProtectedRoute>
                                } />
                                <Route path=":userId" element={
                                    <ProtectedRoute>
                                        <Single />
                                    </ProtectedRoute>
                                } />
                                <Route
                                    path="new"
                                    element={
                                        <ProtectedRoute>
                                            <New inputs={userInputs} title="Add NewJobOfferForm User" />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path="jobs">
                                <Route index element={
                                    <ProtectedRoute>
                                        <JobOffersList />
                                    </ProtectedRoute>
                                } />
                                <Route path=":jobId" element={
                                    <ProtectedRoute>
                                        <JobOffer />
                                    </ProtectedRoute>
                                } />
                                <Route
                                    path="new"
                                    element={
                                        <ProtectedRoute>
                                            <NewJobOfferForm />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path="hiring-process">
                                <Route index element={
                                    <ProtectedRoute>
                                        <HiringProcesses />
                                    </ProtectedRoute>
                                } />
                                <Route path=":hiringProcessId" element={
                                    <ProtectedRoute>
                                        <HiringProcessDetails />
                                    </ProtectedRoute>
                                } />
                                <Route
                                    path="new"
                                    element={
                                        <ProtectedRoute>
                                            <NewJobOfferForm />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path="profile">
                                <Route index element={
                                    <ProtectedRoute>
                                        <EnterpriseProfilePage />
                                    </ProtectedRoute>
                                } />
                            </Route>
                        </Route>
                        <Route path="/dashboard/professional">
                            <Route index element={
                                <ProfessionalDashboard />
                            } />
                            <Route path="contributors">
                                <Route index element={
                                    <ProtectedRoute level="Enterprise">
                                        <ContributorList />
                                    </ProtectedRoute>
                                } />
                                <Route path=":userId" element={
                                    <ProtectedRoute>
                                        <Single />
                                    </ProtectedRoute>
                                } />
                                <Route
                                    path="new"
                                    element={
                                        <ProtectedRoute>
                                            <New inputs={userInputs} title="Add NewJobOfferForm User" />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path="jobs">
                                <Route index element={
                                    <ProtectedRoute>
                                        <JobOffersList />
                                    </ProtectedRoute>
                                } />
                                <Route path=":jobId" element={
                                    <ProtectedRoute>
                                        <JobOffer />
                                    </ProtectedRoute>
                                } />
                                <Route
                                    path="new"
                                    element={
                                        <ProtectedRoute>
                                            <NewJobOfferForm />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path="profile">
                                <Route index element={
                                    <ProtectedRoute>
                                        <ProfessionalProfilePage />
                                    </ProtectedRoute>
                                } />
                            </Route>
                        </Route>
                    </Routes>
                </LanguageContextProvider>
            </AuthProvider>
        </Router>

    </div>
  );
}

export default App;
