import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import Widget from "./Widget";

const EnterpriseWidgetFactory = ({ type, metrics }) => {

  let data;

  switch (type) {
    case "visits":
      data = {
        title: "VISITS",
        isMoney: false,
        link: "See all jobs",
        path:"/dashboard/enterprise/jobs",
        amount: metrics && metrics.numberOfJobVisits,
        diff: metrics && metrics.numberOfJobVisits,
        icon: (
            <VisibilityIcon
                className="icon"
                style={{
                  color: "blue",
                  backgroundColor: "rgba(0, 0, 128, 0.2)",
                }}
            />
        ),
      };
      break;
    case "hiring-processes":
      data = {
        title: "HIRING PROCESSES",
        isMoney: false,
        link: "View all hiring process",
        path:"/dashboard/enterprise/hiring-process",
        amount: metrics && metrics.numberOfHiringProcess,
        diff: metrics && metrics.numberOfHiringProcess,
        icon: (
            <RunningWithErrorsIcon
                className="icon"
                style={{
                  backgroundColor: "rgba(255, 0, 0, 0.2)",
                  color: "crimson",
                }}
            />
        ),
      };
      break;
    case "hiring":
      data = {
        title: "HIRING",
        isMoney: false,
        link: "View all hiring",
        path:"/earnings",
        amount: metrics && metrics.numberOfHiredPeople,
        diff: metrics && metrics.numberOfHiredPeople,
        icon: (
            <GroupAddOutlinedIcon
                className="icon"
                style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
            />
        ),
      };
      break;
    case "contributors":
      data = {
        title: "CONTRIBUTORS",
        isMoney: false,
        link: "See details",
        path:"/dashboard/enterprise/contributors",
        amount: metrics && metrics.numberOfContributors,
        diff: metrics && metrics.numberOfContributors,
        icon: (
            <PersonOutlinedIcon
                className="icon"
                style={{
                  backgroundColor: "rgba(218, 165, 32, 0.2)",
                  color: "goldenrod",
                }}
            />
        ),
      };
      break;
    default:
      break;
  }

  return (
      <Widget data={data} />
  );
}

export default EnterpriseWidgetFactory;