import React, {useState} from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import "./ProgressTracker.scss";

const steps = ['APPLIED', 'INTERVIEW_SCHEDULED', 'INTERVIEWED', 'HIRED'];

const ProgressTracker = ({status}) => {

  console.log(status);
  console.log(steps.indexOf(status));

  const [activeStep, setActiveStep] = useState(steps.indexOf(status));

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
                <Step key={label} {...stepProps} sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}>
                  <div className="step-container">
                    <StepLabel {...labelProps} sx={{ display: "flex", flexDirection: "column" }}>
                      {label}
                    </StepLabel>

                  </div>
                </Step>
            );
          })}
        </Stepper>
      </Box>
  );
}

export default ProgressTracker;