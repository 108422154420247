import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon
  from "@mui/icons-material/MonetizationOnOutlined";
import AccountBalanceWalletOutlinedIcon
  from "@mui/icons-material/AccountBalanceWalletOutlined";
import Widget from "./Widget";
import {useEffect, useState} from "react";
import {useAuth} from "../../context/AuthContext";

const WidgetFactory = ({ type }) => {

  let data;

  const [metrics, setMetrics] = useState();

  const { authUser } = useAuth();

  useEffect(() => {
    const fetchMetrics = async () => {
      const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/usersMetricsMock',
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + authUser.accessToken,
              "Access-Control-Allow-Origin" : "*"
            },
            method: "POST"
          });
      const jsonResult = await result.json();
      setMetrics(jsonResult)
    }

    fetchMetrics().then(result => console.log("downloaded data " + result));
  }, []);

  switch (type) {
    case "user":
      data = {
        title: "USERS",
        isMoney: false,
        link: "See all users",
        path:"/dashboard/customers",
        amount: metrics && metrics.users.amount,
        diff: metrics && metrics.users.diff,
        icon: (
            <PersonOutlinedIcon
                className="icon"
                style={{
                  color: "crimson",
                  backgroundColor: "rgba(255, 0, 0, 0.2)",
                }}
            />
        ),
      };
      break;
    case "order":
      data = {
        title: "ORDERS",
        isMoney: false,
        link: "View all orders",
        path:"/orders",
        amount: metrics && metrics.orders.amount,
        diff: metrics && metrics.orders.diff,
        icon: (
            <ShoppingCartOutlinedIcon
                className="icon"
                style={{
                  backgroundColor: "rgba(218, 165, 32, 0.2)",
                  color: "goldenrod",
                }}
            />
        ),
      };
      break;
    case "earning":
      data = {
        title: "EARNINGS",
        isMoney: true,
        link: "View net earnings",
        path:"/earnings",
        amount: metrics && metrics.earnings.amount,
        diff: metrics && metrics.earnings.diff,
        icon: (
            <MonetizationOnOutlinedIcon
                className="icon"
                style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
            />
        ),
      };
      break;
    case "balance":
      data = {
        title: "BALANCE",
        isMoney: true,
        link: "See details",
        path:"/balance",
        amount: metrics && metrics.balance.amount,
        diff: metrics && metrics.balance.diff,
        icon: (
            <AccountBalanceWalletOutlinedIcon
                className="icon"
                style={{
                  backgroundColor: "rgba(128, 0, 128, 0.2)",
                  color: "purple",
                }}
            />
        ),
      };
      break;
    default:
      break;
  }

  return (
      <Widget data={data} />
      );
}

export default WidgetFactory;