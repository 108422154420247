import "./ContributorList.scss"
import Datatable from "../../../components/datatable/Datatable"
import Navbar from "../../../components/Navbar";
import React, {useEffect, useState} from "react";
import EnterpriseSidebar from "../../../components/sidebars/EnterpriseSidebar";
import {useAuth} from "../../../context/AuthContext";
import EnterpriseContributor
  from "../../../components/content-creator/EnterpriseContributor";

const ContributorList = () => {

  const [contributors, setContributors] = useState([]);

  const { authUser, userInfo } = useAuth();

  console.log(userInfo.enterpriseAccount.enterpriseId);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/getEnterpriseContributors',
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + authUser.accessToken,
              "Access-Control-Allow-Origin" : "*"
            },
            method: "POST",
            body: JSON.stringify({
              enterpriseId: userInfo.enterpriseAccount.enterpriseId
            })
          });
      const jsonResult = await result.json();

      setContributors(jsonResult.contentCreators);
    }

    fetchData().then(result => console.log("downloaded " + result));
  }, []);

    return (
        <div className="contributor-list">
          <Navbar />
          <div className="contributor-list-container">
            <EnterpriseSidebar/>
            <div className="contributor-list-body">
              {
                contributors?.map((contributor) => {
                  return (
                      <EnterpriseContributor contentCreatorData={contributor} />
                  )
                })
              }
            </div>
          </div>
        </div>
    )
}

export default ContributorList