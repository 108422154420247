import Navbar from "../../../components/Navbar";
import React from "react";
import Profile from "../../../components/profile/Profile";
import ProfessionalSidebar
  from "../../../components/sidebars/ProfessionalSidebar";

const ProfessionalProfilePage = () => {

  return (
      <div className="job-list">
        <Navbar />
        <div className="job-list-container">
          <ProfessionalSidebar />
          <div className="job-list-body">
            <Profile />
          </div>
        </div>
      </div>
  )

}

export default ProfessionalProfilePage;