const EnglishContent = {
  dashboard: "Dashboard",
  customers: "Clients",
  products: "Products",
  jobs: "Jobs",
  payments: "Payments",
  stats: "Stats",
  notifications: "Notification",
  settings: "Settings",
  profile: "Profile",
  logout: "Logout",
  contributors: "Contributors",
  jobOffers: "My Offers",
  hiringProcess: "Hiring process",
  homeNavBarContent: {
    companyName: "KAREERR",
    homeTab: "Home",
    contentCreatorTab: "Content Creators",
    dashboardTab: "Dashboard",
  },
  homeWidget: {
    title: "Where industry leaders help you make your dream hire",
    subtitle: "Don't get bogged down reaching out to cold prospects on a million different channels only for your business to feel the pain of wasted time and effort. Let our network of community leaders take the burden from your recruitment process by introducing you to curated candidates, whenever you need.",
    ctaButtonText: "Get Started",
    ctaUrl: "/hire-with-kareerr"
  },
  homeSlideCompaniesTitle: "Empresas que confían en nosotros",
  homeContentCompaniesSlides: [
    {
      ctaPath: "/edreams",
      imgUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/EDreams_ODIGEO_logo.svg/1600px-EDreams_ODIGEO_logo.svg.png",
      title: "eDreams",
      description: "Joan top!"
    },
    {
      ctaPath: "/wallapop",
      imgUrl: "https://ship-quik.com/wp-content/uploads/wallapop.png",
      title: "Wallapop",
      description: "Vende todo lo que quieras papa"
    },
    {
      ctaPath: "/factorial",
      imgUrl: "https://www.hubspot.es/hubfs/Logo_color.png",
      title: "Factorial",
      description: "Jordi, Bernat y ITnig TOPs"
    },
  ],
  homeSlideContentCreatorTitle: "Nuestros creadores de contenido",
  homeContentCreatorSlides: [
    {
      ctaPath: "/mouredev",
      imgUrl: "https://yt3.googleusercontent.com/ytc/AMLnZu8A1wAaWrc94LYTsQNxodZDeODCdOV8IGlU-5MUkw=s900-c-k-c0x00ffffff-no-rj",
      title: "MoureDev",
      description: "Programación y desarrollo de apps"
    },
    {
      ctaPath: "/midudev",
      imgUrl: "https://avatars.githubusercontent.com/u/1561955?v=4",
      title: "@midudev",
      description: "Miguel Ángel Durán"
    },
    {
      ctaPath: "/itnig",
      imgUrl: "https://img-static.ivoox.com/index.php?w=1024&h=1024&url=https://static-1.ivoox.com/canales/8/6/6/5/1271564005668_XXL.jpg?ts=1647969717",
      title: "ITNIG",
      description: "Historias de startups"
    }
  ],
  companyAcquisitionContentForm: {
    title: "Do you want to meet great candidates?",
    subtitle: "Fill out the form below and schedule a call to get access to our full suite of communities and candidates.",
    nameInput: "Name",
    namePlaceHolder: "Name",
    emailInput: "Email",
    emailPlaceHolder: "youremail@company.tld",
    companyWebsiteInput: "Company website",
    companyWebsitePlaceHolder: "https://kareerr.com/",
    roleCompanyInput: "What is your role within the company?",
    roleCompanyPlaceHolder: "Your role",
    lookingForProfessionalsInput: "What role(s) are you primarily interested in hiring for?",
    lookingForProfessionalsPlaceHolder: "e.g. Software Engineer",
    ctaButtonTitle: "Get Started",
    ctaButtonPath: "/confirmation",
    contentCreatorQuestion: "Are you a content creator?",
    contentCreatorText: "Register",
    contentCreatorAcquisitionPath: "/join-kareerr"
  },
  creatorContentHomeWidget: {
    title: "Kareerr pays you to keep doing what you love.",
    subtitle: "Kareerr helps businesses connect with content creators communities just like yours, and allows you to help your audience land awesome opportunities.",
    ctaButtonText: "Get Started",
    ctaUrl: "/join-kareerr"
  },
  contentCreatorAcquisitionContentForm: {
    title: "Sign up to start a Pallet",
    subtitle: "Take a second to fill out this form, and we'll get back to you with next steps.",
    nameInput: "Name",
    namePlaceHolder: "Name",
    emailInput: "Email",
    emailPlaceHolder: "youremail@company.tld",
    contentTypeInput: "Content Type",
    contentTypeOptions: [
      {
        title: "Software development",
        isSelected: false,
      },
      {
        title: "Product development",
        isSelected: false,
      },
      {
        title: "Machine learning",
        isSelected: false,
      },
      {
        title: "Block Chain",
        isSelected: false,
      },
      {
        title: "Marketing",
        isSelected: false,
      },
      {
        title: "Other",
        isSelected: false,
      }
    ],
    contentCreatorUrlInput: "Website / Main Platform Link",
    contentCreatorUrlHolder: "https://kareerr.com/",
    allChannelsInput: "Channels",
    channelsOptions: [
      {
        title: "Youtube",
        isSelected: false,
      },
      {
        title: "Twitch",
        isSelected: false,
      },
      {
        title: "Linkedin",
        isSelected: false,
      },
      {
        title: "Instagram",
        isSelected: false,
      },
      {
        title: "Tiktok",
        isSelected: false,
      },
      {
        title: "Podcast",
        isSelected: false,
      },
      {
        title: "Other",
        isSelected: false,
      }
    ],
    ctaButtonTitle: "Let's go",
    ctaButtonPath: "/confirmation",
    enterpriseQuestion: "Are you a company?",
    enterpriseText: "Register",
    enterpriseAcquisitionPath: "/hire-with-kareerr"
  },
  confirmationPage: {
    confirmationCardTitle: "¡Muchas gracias!",
    confirmationCards : [
      {
        id : "confirmation",
        imgUrl: "/images/confirmation/lyanna.png",
        name: "¡Tu solicitud ha sido enviada correctamente!",
        role: "Nos pondremos en contacto pronto",
        description: "Estamos encantados y con ganas de empezar a trabajar con vosotros :)"
      }
    ],
  },
  footerContent: {
    footerTitle: "Register in our newsletter to be always updated",
    footerSubtitle: "You can unsubscribe at any time",
    footerEmailInputPlaceHolder: "Email",
    footerNameInputPlaceHolder: "Name",
    footerSubscribeButtonText: "Subscribe",
  },
  loginContent: {
    companyName: "KAREERR",
    loginEmailInput: "Email",
    loginEmailInputPlaceHolder: "youremail@company.tld",
    loginPwdInput: "Password",
    loginPwdInputPlaceHolder: "*************",
    loginButtonText: "Login In",
    loginForgotPwdText: "Forgot Password?",
    loginNoAccountText: "Don't have an account?",
    loginRegisterPath: "/hire-with-kareerr",
    loginRegisterText: "Register",
  },
  enterpriseOfferJobsText: "My Offers",
  searchJobsText: "Search Offers",
  addJobText: "Add Offer",
  viewOfferDetailText: "View",
  newFormEnterpriseJobPage: {
    title: "New Offer",
    inputs: [
      {
        id: 1,
        data: "title",
        label: "Title",
        type: "text",
        placeholder: "IOS Software Engineer"
      },
      {
        id: 2,
        data: "conditions",
        label: "Conditions",
        type: "text",
        placeholder: "Permanent"
      },
      {
        id: 3,
        data: "location",
        label: "Location",
        type: "text",
        placeholder: "London"
      },
      {
        id: 4,
        data: "description",
        label: "Description",
        type: "text",
        placeholder: "Description"
      },
      {
        id: 5,
        data: "referral",
        label: "Reward",
        type: "number",
        placeholder: "500€"
      }
    ],
    ctaText: "Añadir"
  },
}

export default EnglishContent;