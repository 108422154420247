import React, {useContext, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Alert } from "./Alert";
import "./Login.css"
import {LanguageContext} from "../context/LanguageContext";

function Login() {

    const [user, setUser] = useState({
        email: "",
        password: "",
    });

    const { login, resetPassword } = useAuth();
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const { content } = useContext(LanguageContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        try {
            await login(user.email, user.password);
            navigate("/dashboard");
        } catch (error) {
            setError(error.message);
        }
    };

    const handleChange = ({ target: { value, name } }) =>
        setUser({ ...user, [name]: value });

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (!user.email) return setError("Write an email to reset password");
        try {
            await resetPassword(user.email);
            setError('We sent you an email. Check your inbox')
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="w-full pt-32 max-w-sm m-auto">
            <div className="login-register-company-logo">
                <i className="login-register-logo-icon" />
                <p className="login-register-company-name">KAREERR</p>
            </div>

            {error && <Alert message={error} />}

            <form
                onSubmit={handleSubmit}
                className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            >
                <div className="mb-4">
                    <label
                        htmlFor="email"
                        className="block text-gray-800 text-sm font-bold mb-2"
                    >
                        {content.loginContent.loginEmailInput}
                    </label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder={content.loginContent.loginEmailInputPlaceHolder}
                    />
                </div>
                <div className="mb-4">
                    <label
                        htmlFor="password"
                        className="block text-gray-800 text-sm font-bold mb-2"
                    >
                        {content.loginContent.loginPwdInput}
                    </label>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder={content.loginContent.loginPwdInputPlaceHolder}
                    />
                </div>

                <div className="flex items-center justify-center py-4">
                    <button
                        className="bg-sky-600 hover:bg-sky-800 text-white w-full rounded-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                    >
                        {content.loginContent.loginButtonText}
                    </button>
                </div>
                <div className="flex items-center justify-center">
                    <a
                        className="inline-block align-baseline font-bold text-sm text-sky-600 hover:text-sky-800"
                        href="#!"
                        onClick={handleResetPassword}
                    >
                        {content.loginContent.loginForgotPwdText}
                    </a>
                </div>
            </form>
            <p className="my-4 text-sm flex justify-between px-3">
                {content.loginContent.loginNoAccountText}
                <Link to={content.loginContent.loginRegisterPath} className="text-sky-800 hover:text-sky-900">
                    {content.loginContent.loginRegisterText}
                </Link>
            </p>
        </div>
    );
}

export default Login;