import React from "react";
import "./JobPositionBasicInfo.scss";

const JobPositionBasicInfo = ({jobBasicInfo}) => {

  console.log(jobBasicInfo);

  return (
      <div className="job-basic-info-container">
        <div className="job-basic-info-box">
          <div className="job-basic-info-company">
            <img
                src={jobBasicInfo.enterprise.logoUrl}
                className="job-basic-info-company-img"
                alt=""
            />
            <div className="job-basic-info-company-info">
              <p className="job-basic-info-company-name">{jobBasicInfo.enterprise.name}</p>
              <p className="job-basic-info-company-profession">{jobBasicInfo.positionTitle}</p>
            </div>
          </div>
        </div>
      </div>
  )
}

export default JobPositionBasicInfo;