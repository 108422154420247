import "./ProductCustomTable.scss";
import "./JobsCustomTable.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import {Link} from "react-router-dom";
import SearchBar from "../searchbar/SearchBar";
import {useState} from "react";

const headers = [
  "Product ID", "Product Name", "Description",
  "Category", "Brand", "Price", "Related products"
];

const ProductCustomTable = ({dataTitle, dataInput}) => {

  const [input, setInput] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let data = (input) ? input : dataInput;

  const requestSearch = (searchedVal) => {
    const filteredData = dataInput?.filter((row) => {
      return row.title.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setInput(filteredData);
  };

  const cancelSearch = () => {
    requestSearch("");
  };

  return (
      <div className="productDatatable">
        <div className="productDatatableTitle">
          {dataTitle ? dataTitle : "Products"}
        </div>
        <div className="productDatatableFilters">
          <SearchBar placeHolder="Find Product" searchAction={requestSearch} clearAction={cancelSearch} />
          <Link to="new" className="link">
            Add New
          </Link>
        </div>
        <Paper className="table">
          <TableContainer sx={{ maxHeight: 500 }}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className="rounded-table-header">
                <TableRow key="tableHeaders">
                  {headers.map((headerTitle) => (
                      <TableCell key={headerTitle} className="tableCell">{headerTitle}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="rounded-table-body">
                {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.productId}>
                      <TableCell className="tableCell">{row.productId}</TableCell>
                      <TableCell className="tableCell">
                        <div className="cellWrapper">
                          <img src={row.imgUrl} alt="" className="productImage" />
                          {row.title}
                        </div>
                      </TableCell>
                      <TableCell className="tableCell">{row.description}</TableCell>
                      <TableCell className="tableCell">{row.category}</TableCell>
                      <TableCell className="tableCell">{row.brand}</TableCell>
                      <TableCell className="tableCell">
                        <span className={`status Approved`}>{row.price}€</span>
                      </TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={data ? data.length : 5}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

      </div>
  );
};

export default ProductCustomTable;