import React from "react";
import "./ContentCreatorBasicProfileCard.scss";

const ContentCreatorBasicProfileCard = ({contentCreatorData}) => {

  return (
      <div className="content-creator-card-container">
        <div className="content-creator-card-box">
          <div className="content-creator-card-profile">
            <img
                src={"/images/grison.jpeg"}
                className="content-creator-card-profile-img"
                alt=""
            />
            <div className="content-creator-card-profile-info">
              <p className="content-creator-card-profile-name">{contentCreatorData.name} {contentCreatorData.lastName}</p>
              <p className="content-creator-card-profile-profession">{contentCreatorData.contentCreatorAccount.profession}</p>
              <p className="content-creator-card-profile-channel">{contentCreatorData.contentCreatorAccount.channelUrl}</p>
            </div>
          </div>
        </div>
      </div>
  )
}

export default ContentCreatorBasicProfileCard;