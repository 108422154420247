
import React from "react";
import TitledSlideCard from "./TitledSlideCard";
import ImageSlideCard from "./ImageSlideCard";

const SlideCardFactory = ({type, slide}) => {

  const createSlideCard = () => {
    switch (type) {
      case "withTitle":
        return (
            <TitledSlideCard slide={slide} />
        );
      case "imageSlide":
        return (
            <ImageSlideCard slide={slide} />
        );
      default:
        return (
            <TitledSlideCard slide={slide} />
        );
    }
  };

  return (
      createSlideCard(type, slide)
  );
}

export default SlideCardFactory;