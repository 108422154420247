import "./JobOffersList.scss";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../../context/AuthContext";
import Navbar from "../../../components/Navbar";
import EnterpriseSidebar from "../../../components/sidebars/EnterpriseSidebar";
import EnterpriseJobsCustomTable
  from "../../../components/table/EnterpriseJobsCustomTable";

const JobOffersList = () => {

  const [rows, setRows] = useState([]);

  const { authUser } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/getJobs',
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + authUser.accessToken,
              "Access-Control-Allow-Origin" : "*"
            },
            method: "POST"
          });
      return await result.json();
    }
    fetchData().then(result => setRows(result));
  }, []);

  return (
      <div className="job-offers-list">
        <Navbar />
        <div className="job-offers-list-container">
          <EnterpriseSidebar />
          <div className="job-offers-list-body">
            <EnterpriseJobsCustomTable />
          </div>
        </div>
      </div>
  )
}

export default JobOffersList;