import React from "react";
import {Link} from "react-router-dom";
import "./TitledSlideCard.scss"

const TitledSlideCard = ({slide}) => {

  return (
      <div className="titled-slide-card">
        <Link to={slide.ctaPath}>
          <img src={slide.imgUrl} alt="" className="titled-slide-card-image"/>
        </Link>
        <p className="titled-slide-card-title">{slide.title}</p>
        <p className="titled-slide-card-description">{slide.description}</p>
      </div>
  )
}

export default TitledSlideCard;