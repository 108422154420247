import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import "./Offer.scss";
import ContentCreatorBasicProfileCard
  from "../../components/content-creator/ContentCreatorBasicProfileCard";
import Navbar from "../../components/Navbar";
import DetailedJobPosition from "../../components/jobs/DetailedJobPosition";

const Offer = () => {

  const { token } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/getTokenInfo',
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin" : "*"
            },
            method: "POST",
            body: JSON.stringify({
              kareerrToken: encodeURIComponent(token)
            })
          });
      const jsonResult = await result.json();
      console.log(jsonResult);
      setData(jsonResult)
    }

    fetchData().then(result => console.log("downloaded " + result));
  }, []);

  return (
      <div>
        <Navbar />
        <div className="offer-body-page">
          <div className="offer-container">
            <div className="offer-sticky-card-content">
              { data && <ContentCreatorBasicProfileCard contentCreatorData={data.user} /> }
            </div>
            <div className="offer-detailed-info-content">
              { data && <DetailedJobPosition jobInfo={data.job} /> }
            </div>
          </div>
        </div>
      </div>

  );
}

export default Offer;