import React, {useEffect, useRef, useState} from "react";
import "./Profile.scss";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {useAuth} from "../../context/AuthContext";

const Profile = () => {

  const { authUser, userInfo, profile, profileImage} = useAuth();

  const addUserProfileImage = async (body) => {
    const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/addUserProfileImage',
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          body: body
        });
    const jsonResult = await result.json();
    console.log(jsonResult);
    return jsonResult;
  }

  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    const file = event.target.files[0];
    if(!file) return;

    // upload file
    const formData = new FormData();
    formData.append(
        "file",
        file,
        file.name,
    );

    formData.append(
        "userId",
        userInfo.userId,
    );

    console.log(formData);

    addUserProfileImage(formData).then(result => {
      console.log("downloaded " + result);
      file.isUploading = false;
      console.log("UPLOADED FINISHED");
    });
  };

  return (
        <div className="profile-page">
          <div className="profile-info-container">
            <img src={profileImage} alt="" className="profile-rounded-image"/>
            <button onClick={handleClick}>
              <CameraAltIcon className="icon" />
            </button>
            <input type="file"
                   ref={hiddenFileInput}
                   onChange={handleChange}
                   style={{display: 'none'}}/>
            <h5 className="profile-name">{profile && profile.user.name}</h5>
          </div>
          <p>{profile && profile.profile.profileBio}</p>
        </div>
  );
}

export default Profile;