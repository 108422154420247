import "./Widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link } from "react-router-dom";

const Widget = ({ data }) => {

    return (
        <div className="widget">
            <div className="left">
                <span className="title">{data.title}</span>
                <span className="counter">
          {data.isMoney && "$"} {data.amount}
        </span>
                <Link to={data.path} className="link">{data.link}</Link>
            </div>
            <div className="right">
                <div className="percentage positive">
                    <KeyboardArrowUpIcon />
                    {data.diff} %
                </div>
                {data.icon}
            </div>
        </div>
    );
};

export default Widget;