import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Alert} from "../../../pages/Alert";
import "./ContentCreatorAcquisitionForm.scss";

const ContentCreatorAcquisitionForm = ({contentForm}) => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contentCreatorUrl, setContentCreatorUrl] = useState("");
  const [contentTypes, setContentTypes] = useState(contentForm.contentTypeOptions);
  const [channels, setChannels] = useState(contentForm.channelsOptions);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const validEmailRegExp = new RegExp("([A-Za-z0-9]+[A-Za-z0-9._]+[@][a-z]*[.][a-z]{3})");

  const fetchData = async (body) => {
    console.log(body);
    const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/addForm',
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          body: JSON.stringify(body)
        });
    const jsonResult = await result.json();
    console.log(jsonResult);
    return jsonResult;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    let formError = validateForm();
    console.log("ENTER");
    console.log(formError);
    if (formError === "") {
      let formData = buildFormData();
      fetchData(formData).then(result => {
        console.log("downloaded " + result);
        console.log("Navigating " + result);
        navigate(contentForm.ctaButtonPath);
      });
    } else {
      setError(formError);
    }
  };

  const validateForm = () => {
    if (name === "" || name === "undefined") {
      return "Name could not be empty";
    }
    console.log(email);
    if (!validEmailRegExp.test(email)) {
      return "Email is not following a correct pattern";
    }
    let contentTypesSelected = contentTypes?.filter(contentType => contentType.isSelected).map(contentType => contentType.title);
    if (contentTypesSelected.length < 1) {
      return "You must choose at least one of the Content Type options";
    }
    if (contentCreatorUrl === "" || contentCreatorUrl === "undefined") {
      return "Your url could not be empty";
    }
    let channelsSelected = channels?.filter(channel => channel.isSelected).map(channel => channel.title);
    if (channelsSelected.length < 1) {
      return "You must choose at least one of the Channel options";
    }
    return "";
  }
  const buildFormData = () => {
    let contentTypesSelected = contentTypes?.filter(contentType => contentType.isSelected).map(contentType => contentType.title);
    let channelsSelected = channels?.filter(channel => channel.isSelected).map(channel => channel.title);
    let bodyForm = {
      name : name,
      email: email,
      contentCreatorUrl: contentCreatorUrl,
      areaOfKnowledge: contentTypesSelected,
      channels: channelsSelected
    };
    return {
      formType : "contentCreatorForm",
      bodyForm : bodyForm
    }
  }

  const handleNameInput = (nameInput) => {
    setName(nameInput);
  }

  const handleEmailInput = (emailInput) => {
    setEmail(emailInput);
  }

  const handleContentTypeOptionClick = (index) => {
    contentTypes[index].isSelected = !contentTypes[index].isSelected;
    setContentTypes(Object.assign([], contentTypes));
    //console.log(contentTypes?.filter(contentType => contentType.isSelected).map(contentType => contentType.title));
  };

  const handleContentCreatorUrlInput = async (url) => {
    setContentCreatorUrl(url);
  }

  const handleChannelOptionClick = (index) => {
    channels[index].isSelected = !channels[index].isSelected;
    setChannels(Object.assign([], channels));
  };

  return (
      <div className="logo-titled-form-container">
        <div className="w-full py-16 max-w-lg m-auto text-black">

          <div className="logo-titled-form-text-container">
            <h1 className="logo-titled-form-title">{contentForm.title}</h1>
            <h2 className="logo-titled-form-subtitle">{contentForm.subtitle}</h2>
          </div>

          {error && <Alert message={error} />}

          <form
              onSubmit={handleSubmit}
              className="bg-white shadow-lg rounded-2xl px-8 pt-6 pb-6 mb-4"
          >
            <div className="mb-4">
              <label
                  htmlFor="name"
                  className="block text-gray-800 text-sm font-bold mb-2"
              >
                {contentForm.nameInput}
              </label>
              <input
                  type="text"
                  onChange={(e) => handleNameInput(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder={contentForm.namePlaceHolder}
              />
            </div>

            <div className="mb-4">
              <label
                  htmlFor="email"
                  className="block text-gray-800 text-sm font-bold mb-2"
              >
                {contentForm.emailInput}
              </label>
              <input
                  type="email"
                  onChange={(e) => handleEmailInput(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder={contentForm.emailPlaceHolder}
              />
            </div>

            <div className="mb-4">
              <label
                  htmlFor="profession"
                  className="block text-gray-800 text-sm font-bold mb-2"
              >
                {contentForm.contentTypeInput}
              </label>
              <div className="channels-options-tag-container">
                {
                  contentTypes?.map((contentType, index) => {
                    return <div key={contentType.title}>
                      <div role="button" className={"channels-options-tag channels-options-tag" + (contentTypes[index].isSelected ? "-selected" : "-unselected")}
                           onClick={() => handleContentTypeOptionClick(index)}>
                        {contentType.title}
                      </div>
                    </div>
                  })
                }
              </div>

            </div>

            <div className="mb-4">
              <label
                  htmlFor="content-creator-url"
                  className="block text-gray-800 text-sm font-bold mb-2"
              >
                {contentForm.contentCreatorUrlInput}
              </label>
              <input
                  type="text"
                  onChange={(e) => handleContentCreatorUrlInput(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder={contentForm.contentCreatorUrlHolder}
              />
            </div>

            <div className="mb-4">
              <label
                  htmlFor="channel"
                  className="block text-gray-800 text-sm font-bold mb-2"
              >
                {contentForm.allChannelsInput}
              </label>
              <div className="channels-options-tag-container">
                {
                  channels?.map((skill, index) => {
                    return <div key={skill.title}>
                      <div role="button" className={"channels-options-tag channels-options-tag" + (channels[index].isSelected ? "-selected" : "-unselected")}
                           onClick={() => handleChannelOptionClick(index)}>
                          {skill.title}
                      </div>
                    </div>
                  })
                }
              </div>

            </div>

            <div className="py-2">
              <button className="w-full bg-sky-600 hover:bg-sky-800 text-white rounded-full font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline">
                {contentForm.ctaButtonTitle}
              </button>
            </div>

          </form>
          <p className="my-4 text-sm flex justify-between px-3">
            {contentForm.enterpriseQuestion}
            <Link to={contentForm.enterpriseAcquisitionPath} className="text-sky-800 hover:text-sky-900">
              {contentForm.enterpriseText}
            </Link>
          </p>
        </div>
      </div>
  );

};

export default ContentCreatorAcquisitionForm;