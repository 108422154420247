import React from "react";
import "./CardCollection.scss"
import CardCollectionRow from "./CardCollectionRow";
import CardCollectionTable from "./CardCollectionTable";

const CardCollection = ({collectionTitle, type = "", cards, needsSplit = false, chunkSize = 3}) => {

  const splitCards = (cardsToSplit) => {
    let resultArray = [];
    for (let i = 0; i < cardsToSplit.length; i += chunkSize) {
      const chunk = cardsToSplit.slice(i, i + chunkSize);
      resultArray.push(chunk);
    }
    return resultArray;
  }

  return (
      <div className="card-collection">
        <h1>{collectionTitle}</h1>
        <div className="card-collection-container">
          <div className="card-collection-wrapper">
            {
              needsSplit
                  ? <CardCollectionTable type={type} cardsRows={splitCards(cards)} />
                  : <CardCollectionRow type={type} cards={cards} />
            }
          </div>
        </div>

      </div>
  )
}

export default CardCollection