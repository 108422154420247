import "./JobsCustomTable.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {Link} from "react-router-dom";
import SearchBar from "../searchbar/SearchBar";
import {useContext, useEffect, useState} from "react";
import {useAuth} from "../../context/AuthContext";
import {LanguageContext} from "../../context/LanguageContext";

const EnterpriseJobsCustomTable = () => {

    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    const { authUser, userInfo } = useAuth();
    const { language, content } = useContext(LanguageContext);

    useEffect(() => {
        const fetchSchemaData = async () => {
            const result = await fetch('https://europe-west6-kareerr-7415c.cloudfunctions.net/getEnterpriseJobTableSchemaData',
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + authUser.accessToken,
                        "Access-Control-Allow-Origin" : "*"
                    },
                    method: "POST",
                    body: JSON.stringify({
                        enterpriseId: encodeURIComponent(userInfo.enterpriseAccount.enterpriseId),
                        language: language
                    })
                });
            return await result.json();
        };

        fetchSchemaData().then(result => {
            console.log(result)
            setColumns(result.columns);
            setData(result.rows);
            setRows(result.rows);
        });
    }, []);

    const requestSearch = (searchedVal) => {
        const filteredData = data.filter((row) => {
            return row.positionTitle.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setRows(filteredData);
    };

    const cancelSearch = () => {
        requestSearch("");
    };

    return (
        <div className="datatable">
            <div className="datatableTitle">
                {content.enterpriseOfferJobsText}
            </div>
            <div className="datatableFilters">
                <SearchBar placeHolder={content.searchJobsText} searchAction={requestSearch} clearAction={cancelSearch} />
                <Link to="new" className="link">
                    {content.addJobText}
                </Link>
            </div>
            <TableContainer component={Paper} className="table">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow key="tableHeaders">
                            {columns?.map((headerTitle, index) => (
                                <TableCell key={index} className="tableCell">{headerTitle}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell className="tableCell">{row.enterprise.name}</TableCell>
                                <TableCell className="tableCell">
                                    <div className="cellWrapper">
                                        <img src={row.enterprise.logoUrl} alt="" className="image" />
                                        {row.enterprise.name}
                                    </div>
                                </TableCell>
                                <TableCell className="tableCell">{row.positionTitle}</TableCell>
                                <TableCell className="tableCell">{row.contractConditions}</TableCell>
                                <TableCell className="tableCell">{row.location}</TableCell>
                                <TableCell className="tableCell">{row.visits}</TableCell>
                                <TableCell className="tableCell">{row.applications}</TableCell>
                                <TableCell className="tableCell">
                                    <div className="cellAction">
                                        <Link to={row.jobId} style={{ textDecoration: "none" }}>
                                            <div className="viewButton">{content.viewOfferDetailText}</div>
                                        </Link>
                                    </div>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default EnterpriseJobsCustomTable;