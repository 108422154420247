import React from "react";
import CardSlider from "./CardSlider";
import "./CenteredCardSliderContainer.scss";

const CenteredCardSliderContainer = ({slideCardType, sliderTitle, cardSlides}) => {

  return (
        <div className="center-card-slider-container">
          <CardSlider slideCardType={slideCardType} sliderTitle={sliderTitle} cardSlides={cardSlides}/>
        </div>
      )
}

export default CenteredCardSliderContainer;